import {Box, styled} from "@mui/material";
import logo from "../assets/images/fundacjaLogo.svg";
import {Link} from "react-router-dom";

const LogoImage = styled("img")(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        height: '100%',
        width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
        height: '50px',
        width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: 'auto',
    },
}));

type Props = {
    sx?: any;
}

const Logo = ({sx}: Props) => {
    return <Box sx={sx}><Link to={"/"}><LogoImage src={logo} alt={"Logo"}/></Link></Box>
}


export default Logo;
