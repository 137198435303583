import {useContext} from "react";
import ContentContext from "../../store/content-context";
import {Grid, Typography} from "@mui/material";
import PaymentBanner from "./PaymentBanner";
import {GreenButton} from "../../ui/GreenButton";

type Props = {
    content: any;
}

const TransferDetails = ({content}: Props) => {
    const {foundation} = useContext(ContentContext);


    return <Grid container justifyContent={"center"} alignItems={"center"} sx={{width: {lg: "1175px", xs: "90%"}, m: "0 auto", color: "transparent", position: "relative"}}>
        <Grid item container flexDirection={{xs: "column", lg: "row"}} sx={{width: "80%"}} flexWrap={"nowrap"} alignItems={"center"}>
            <Grid item container flexDirection={"column"} justifyContent={"center"}>
                <Typography variant={"h6"} color={"primary"}textAlign={{xs: "center", lg: "left"}}>Wpłać darowiznę na konto Fundacji:</Typography>
                <Typography variant={"h6"} color={"primary"}textAlign={{xs: "center", lg: "left"}} sx={{fontWeight: 400}} mt={"29px"}>Konto Fundacji: {foundation.bank}</Typography>
                <Typography variant={"h6"} color={"primary"}textAlign={{xs: "center", lg: "left"}} sx={{fontWeight: 400}}>Nr rachunku: {foundation.account}</Typography>
                <Typography variant={"h6"} color={"primary"}textAlign={{xs: "center", lg: "left"}} sx={{fontWeight: 400}} mt={"29px"}>Konto walutowe: {foundation.euroPlAccount}</Typography>
                <Typography variant={"h6"} color={"primary"}textAlign={{xs: "center", lg: "left"}} sx={{fontWeight: 400}}>Kod SWIFT (BIC): {foundation.swiftCode}</Typography>
            </Grid>
            <Grid item ml={{xs: 0, lg:"67px"}} mt={{xs: "96px", lg: 0}}>
                <GreenButton onClick={() => window.open("/transfer-form", "_blank")}>Wygeneruj szablon przelewu</GreenButton>
            </Grid>
        </Grid>
        <PaymentBanner content={content.banner_text} image={content.image} link={content.banner_link}/>
    </Grid>
}

export default TransferDetails;