import {
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    styled,
    Typography,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import CvFormContext, {
    FIND_OUT,
    HOBBY,
    MOTIVATION,
    SET_AGREEMENT,
    SET_WEEK_DAY,
    SET_YES_NO,
    SKILLS,
    SOFT_SKILLS,
    VALIDATE_SECOND_STEP
} from "./cv-form-context";
import {StyledField} from "./CvForm";
import CustomCheckbox from "../../ui/CustomCheckbox";
import SnackbarContext from "../../store/snackbar-context";
import {GreenButton} from "../../ui/GreenButton";
import {StyledLabel} from "../../ui/StyledTextfield";
import {sendCv} from "../../api/contact-api";

type Props = {
    onNext: () => void;
    onBack: () => void;
}

const StyledFormLabel = styled(FormLabel)(({theme}) => ({
    fontWeight: 700,
    fontSize: "18px",
    paddingBottom: "20px",
    whiteSpace: "normal",
    height: "auto",
    color: theme.palette.primary.main,
}))

const StyledRadio = styled(Radio)(({theme}) => ({
    color: theme.palette.custom.greenDarkTextBlack,
    '&.Mui-checked': {
        color: theme.palette.custom.greenDarkTextBlack,
    },
}))

const AdditionalQuestions = ({onNext, onBack}: Props) => {
    const {palette} = useTheme();
    const {setMsg} = useContext(SnackbarContext)
    const {fields, cvFile, openQuestions, cvQuestions, yesNoQuestions, setter, isSecondStepValidated, weekDays, agreements} = useContext(CvFormContext);
    const openQuestionsKeys = [FIND_OUT, HOBBY, SKILLS, MOTIVATION, SOFT_SKILLS];
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        weekDays.forEach((day, index) => setter({type: SET_WEEK_DAY, value: {index, ticked: !selectAll}}));
        setSelectAll(!selectAll);
    }

    const handleOnNext = () => {
        setter({type: VALIDATE_SECOND_STEP});
        const error = getErrors();
        if(error) {
            setMsg({msg: error, severity: "error"})
        } else {
            sendCv(fields, cvQuestions, openQuestions, yesNoQuestions, weekDays, cvFile, (error) => setMsg({msg: error}))
                .then(() => onNext())
                .catch((error) => setMsg({msg: "Wystąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później"}));
        }
    }

    const getErrors = () => {
        let result = null;
        openQuestions.forEach(item => {
            if(item && item.error) result = item.error;
        })
        const checkedDays = weekDays.filter(item => item.value);
        if(checkedDays.length === 0 ){
            result = weekDays[0].error;
        }
        agreements.forEach(item => {
            if(!item.value) result = item.error;
        })
        return result;
    }

    return <Grid item container sx={{p: "0 64px"}} flexDirection={"column"}>
        <Typography variant={"h5"} mt={"48px"} mb={"48px"} color={palette.custom.greenDarkTextBlack} textAlign={"left"} sx={{fontWeight: 700}}>Pytania dodatkowe</Typography>
        <Grid item container spacing={4}>
            {yesNoQuestions.map((item, index) => {
                return item && <Grid item xs={12} key={item.label}>
                    <FormControl>
                        <StyledFormLabel>{item.label}</StyledFormLabel>
                        <RadioGroup sx={{flexDirection: "row"}} value={item.value} onChange={({target: {value}}) => setter({type: SET_YES_NO, value: {index, ticked: value === 'true'}})}>
                            <FormControlLabel value={true} control={<StyledRadio/>} label={"Tak"} sx={{pr: "20px"}}/>
                            <FormControlLabel value={false} control={<StyledRadio/>} label={"Nie"} sx={{pr: "20px"}}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            })}
        </Grid>
        <Grid item container spacing={4} mt={2}>
            {openQuestionsKeys.map((key, index) => {
                const field = openQuestions.get(key);
                return field && <Grid item xs={12} key={key}>
                    <StyledLabel htmlFor={field.label}>{field.label}</StyledLabel>
                    <StyledField multiline rows={index > 0 ? 4 : 1} fullWidth id={field.label} value={field.value} placeholder={field.placeholder} error={field.error != "" && isSecondStepValidated}
                                 onChange={({target: {value}}) => setter({type: key, value: value})}/>
                </Grid>
            })}
        </Grid>
        <Grid item container spacing={2} mt={2}>
            <Grid item>
                <Typography variant={"body2"} mt={"0px"} mb={"0px"} color={palette.primary.light} textAlign={"left"} sx={{fontWeight: 700}}>W jakie dni tygodnia dysponujesz czasem?*(Wymagana obecność to min. 1 raz w tygodniu (pn-pt) w godzinach między 15:00 a 20:00 lub w sobotę 11:00-20:00.)</Typography>
            </Grid>
            <Grid item xs={12}>
            <FormGroup sx={{flexDirection: "row", justifyContent: {xs: "start", sm: "space-between"}}}>
            {weekDays.map((day, index) => {
                return <FormControlLabel control={<CustomCheckbox isError={true} checkboxProps={{checked: day.value, onChange: ({target: {value}}) => setter({type: SET_WEEK_DAY, value: {index, ticked: !day.value}})}}/>} label={day.label}/>
            })}
            </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <FormGroup sx={{flexDirection: "row", justifyContent: "space-between"}}>
                    <FormControlLabel control={<CustomCheckbox isError={true} checkboxProps={{checked: selectAll, onChange: () => handleSelectAll()}} />}
                                      label={"Zaznacz wszystkie"}/>
                </FormGroup>
            </Grid>
        </Grid>
        <Typography variant={"body2"} mt={"40px"} mb={"17px"} color={palette.primary.light} textAlign={"left"}>*Pola obowiązkowe</Typography>
        <Grid item xs={12}>
            <FormGroup>
                {agreements.map((item, index) => (
                    <FormControlLabel control={<CustomCheckbox isError={!item.value && !isSecondStepValidated} checkboxProps={{checked: item.value, onChange: () => setter({type: SET_AGREEMENT, value: {index, ticked: !item.value}})}} />}
                                      label={item.label} sx={{color: palette.primary.light}}/>
                ))}
            </FormGroup>
        </Grid>
        <Grid item container justifyContent={{xs:"center", sm: "end"}} mt={"48px"} mb={"48px"}>
            <GreenButton onClick={() => onBack()} sx={{color: palette.custom.greenDarkTextBlack, backgroundColor: "white", mr: {xs: "auto", sm: "40px"}}}>Wróć</GreenButton>
            <GreenButton onClick={() => handleOnNext()}>Dalej</GreenButton>
        </Grid>
    </Grid>
}

export default AdditionalQuestions;
