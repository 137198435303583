import {Button, Collapse, Divider, Grid, IconButton, styled, Typography, useMediaQuery, useTheme} from "@mui/material";
import SectionTitle from "../ui/SectionTitle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {useState} from "react";
import DownloadTile from "../ui/DownloadTile";

type Props = {
    content: any;
}

type FilesGroupProps = {
    group: {
        title: string
        subtitle: string;
        files: any[];
    };
}

export const StyledButton = styled(Button)({
    height: "50px",
    borderRadius: "100px",
    border: "2px solid",
    fontWeight: 700,
    textTransform: "none",
    whiteSpace: "nowrap",
})

const FilesGroup = ({group}: FilesGroupProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const {palette, breakpoints} = useTheme();
    const breakpoint = useMediaQuery(breakpoints.up('md'));

    const downloadAll = (files: any[]) => {
        files && files.forEach(({file}: any) => {
            const filename = file.url.substring(file.url.lastIndexOf('/')+1);
            const link = document.createElement('a')
            link.href = file.url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        })
    }

    return <Grid item container flexDirection={"column"} mb={"47px"} mt={"47px"}>
        <Grid item container justifyContent={"space-between"} wrap={"nowrap"}>
            <Grid item justifyContent={"center"} flexDirection={"column"}>
                <Grid item container>
                    <Typography variant={"h6"} sx={{color: palette.primary.main}}>{group.title}</Typography>
                    <Typography variant={"h6"} sx={{fontWeight: 400, color: palette.primary.main}}>&nbsp;&#x25cf;&nbsp;{group.files ? group.files.length : 0} plików</Typography>
                </Grid>
                <Typography sx={{color: palette.primary.main}}>{group.subtitle}</Typography>
            </Grid>
            {!isExpanded && breakpoint && <Grid item sx={{display: "flex"}} alignItems={"start"}>
                 <StyledButton color={"secondary"} onClick={() => downloadAll(group.files)}>Pobierz wszystko</StyledButton>
            </Grid>}
            <Grid item sx={{display: "flex"}} alignItems={"start"}>
                <IconButton sx={{mt: "-15px", mr: "-15px"}} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <ArrowDropUpIcon fontSize={"large"} sx={{color: palette.primary.main}}/> : <ArrowDropDownIcon fontSize={"large"} sx={{color: palette.primary.main}}/>}
                </IconButton>
            </Grid>
        </Grid>
            <Collapse in={isExpanded}>
                <Grid item sx={{mt: "48px", mb: "48px"}}>
                    {group.files && group.files.map((file: any, index: number) => <DownloadTile key={index} title={file.file.title} url={file.file.url} subtype={file.file.subtype} dark={index % 2 != 0}/>)}
                </Grid>
            </Collapse>
        {isExpanded && <Grid item  sx={{ml: "auto"}}>
            <IconButton onClick={() => {}}>
                <StyledButton color={"secondary"} sx={{padding: "20px 48px"}} onClick={() => downloadAll(group.files)}>Pobierz wszystko</StyledButton>
            </IconButton>
        </Grid>}
        <Grid item>
            {!isExpanded && <Divider sx={{backgroundColor: palette.custom.greenDarkText, width: "80%", m:"auto", mt: "45px"}}/>}
        </Grid>
    </Grid>
}

const DownloadFilesGroup = ({content}: Props) => {
    const {palette} = useTheme();

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", position: "relative"}}>
        <SectionTitle title={content.title} color={palette.custom.greenDarkTextBlack}/>
        <Grid item container sx={{width: {lg: "1175px", xs: "90%"}, m: "0 auto",mb: "50px", color: "transparent"}}>
            <Grid item container justifyContent={"end"} alignItems={"center"}>
                <Typography sx={{fontWeight: 700, color: palette.primary.main}}>Wybierz {content.group_by}</Typography>
                <ArrowDropDownIcon fontSize="small" sx={{color: palette.primary.main}}/>
            </Grid>
            {content.files_group.map((group: any) => <FilesGroup key={group.title} group={group}/>)}
        </Grid>
    </Grid>
}

export default DownloadFilesGroup;
