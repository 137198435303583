import React, { createContext, useContext, useState, ReactNode } from 'react';
import {createTheme, ThemeProvider, Theme, CssBaseline, PaletteColorOptions, GlobalStyles, Box} from '@mui/material';

type ThemeContextType = {
	fontSize: number;
	isHighContrast: boolean;
	toggleContrast: () => void;
	increaseFontSize: () => void;
	decreaseFontSize: () => void;
	resetFontSize: () => void;
	theme: Theme;
}

declare module "@mui/material/styles" {
	interface Palette {
		custom: {
			main: string,
			greenDarkText: string,
			greenDarkTextBlack: string;
			greenLightText: string,
			white: string,
			whiteText: string,
			grey: string,
			greenLightTextWhite: string,
		};
	}
	interface PaletteOptions {
		custom?: {
			main: string,
			greenDarkText: string,
			greenDarkTextBlack: string;
			greenLightText: string,
			white: string,
			whiteText: string,
			grey: string,
			greenLightTextWhite: string,
		};
	}
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useCustomTheme = () => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useCustomTheme must be used within a ThemeProvider');
	}
	return context;
};

export const CustomThemeProvider = ({ children }: { children: ReactNode }) => {
	const [fontSize, setFontSize] = useState(16);
	const [isHighContrast, setIsHighContrast] = useState(false);

	const increaseFontSize = () => setFontSize((prev) => Math.min(prev + 2, 24));
	const decreaseFontSize = () => setFontSize((prev) => Math.max(prev - 2, 12));
	const resetFontSize = () => setFontSize(17);
	const toggleContrast = () => setIsHighContrast((prev) => !prev);

	const theme = createTheme({
		palette: {
			primary: {
				main: isHighContrast ? '#000' : '#424242',
				light: isHighContrast ? '#000' : '#787885',
			},
			secondary: {
				light: isHighContrast ? '#000' : '#AACE38',
				main: isHighContrast ? '#000' : '#3D8D4F',
				dark: isHighContrast ? '#fff' : '#424242'
			},
			grey: {
				100: isHighContrast ? '#fff' : '#f5f5f5',
			},
			custom: {
				main: isHighContrast ? '#000' : '#424242',
				greenDarkText: isHighContrast ? '#fff' : '#3D8D4F',
				greenDarkTextBlack: isHighContrast ? '#000' : '#3D8D4F',
				greenLightText: isHighContrast ? '#000' : '#AACE38',
				white: isHighContrast ? '#000' : '#fff',
				whiteText: isHighContrast ? '#fff' : '#fff',
				grey: isHighContrast ? '#cfcfcf' : '#f5f5f5',
				greenLightTextWhite: isHighContrast ? '#fff' : '#AACE38',
			},
		},
		typography: {
			fontFamily: [
				'Sen-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
				'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
				'sans-serif'
			].join(','),
			fontSize: fontSize,
			body1: {
				fontSize: `${fontSize}px`,
			},
			h1: {
				fontSize: `${fontSize * 3.06}px`,
			},
			h2: {
				fontSize: `${fontSize * 2.82}px`,
			},
			h4: {
				fontSize: `${fontSize * 2.47}px`,
				fontWeight: 700,
			},
			h5: {
				fontSize: `${fontSize * 1.88}px`,
				fontWeight: 700,
			},
			h6: {
				fontSize: `${fontSize * 1.24}px`,
				fontWeight: 700,
			}
		},
	});

	return (
		<ThemeContext.Provider
			value={{ fontSize, isHighContrast, toggleContrast, increaseFontSize, decreaseFontSize, resetFontSize, theme }}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};
