import {createContext, ReactNode, useReducer} from "react";
import isEmail from "validator/lib/isEmail";
import isPostalCode from "validator/lib/isPostalCode"
import isMobilePhone from "validator/lib/isMobilePhone"

export const FIRST_NAME = 'setFirstName';
export const LAST_NAME = 'setLastName';
export const STREET = 'setStreet';
export const CITY = 'setCity';
export const EMAIL = 'setEmail';
export const PHONE = 'setPhone';
export const ZIP_CODE = 'setZipCode';
export const DATE_OF_BIRTH = 'setDateOfBirth';
export const EDUCATION= 'setEducation';
export const EDUCATION_YEAR = 'setYear';
export const FIND_OUT = 'setFindOut';
export const HOBBY = 'setHobby';
export const SKILLS = 'setSkills';
export const MOTIVATION = 'setMotivation';
export const SOFT_SKILLS = 'setSoftSkills';
export const SET_YES_NO = 'setYesNo';
export const SET_WEEK_DAY = 'setWeekDay';
export const SET_AGREEMENT = 'setAgreement';
export const CV_FILE = 'setCvFile';
export const VALIDATE_FIRST_STEP = 'setValidateFirstStep';
export const VALIDATE_SECOND_STEP = 'setValidateSecondStep';

export type FieldType = {
    label: string;
    value: string;
    error: string | undefined;
    errorMessage: string;
    placeholder?: string | undefined;
}

export type CheckboxType = {
    label: string;
    value: boolean;
    error: string | undefined;
    errorMessage: string;
}

type FormStateType = {
    fields: Map<string, FieldType>;
    cvQuestions: Map<string, FieldType>;
    openQuestions: Map<string, FieldType>;
    yesNoQuestions: CheckboxType[];
    agreements: CheckboxType[];
    weekDays: CheckboxType[];
    cvFile: File | null;
    isFirstStepValidated: boolean;
    isSecondStepValidated: boolean;
    setter: (data: ActionType) => void;
}

type ActionType = {
    type: string;
    value?: any;
};

const cvFormReducer = (state: FormStateType, action: ActionType): any => {
    switch (action.type) {
        case FIRST_NAME: {
            const firstName = state.fields.get(FIRST_NAME);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [FIRST_NAME, {
                    ...firstName,
                        value: action.value,
                        error: action.value.length == 0 && firstName && firstName.errorMessage,
                    }]
                ])
            };
        }
        case STREET: {
            const street = state.fields.get(STREET);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [STREET, {
                        ...street,
                        value: action.value,
                        error: action.value.length < 4 && street && street.errorMessage,
                    }]
                ])
            };
        }
        case LAST_NAME: {
            const lastName = state.fields.get(LAST_NAME);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [LAST_NAME, {
                        ...lastName,
                        value: action.value,
                        error: action.value.length < 2 && lastName && lastName.errorMessage,
                    }]
                ])
            };
        }
        case CITY: {
            const city = state.fields.get(CITY);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [CITY, {
                        ...city,
                        value: action.value,
                        error: action.value.length < 2 && city && city.errorMessage,
                    }]
                ])
            };
        }
        case DATE_OF_BIRTH: {
            const dob = state.fields.get(DATE_OF_BIRTH);
            const dobRegex = new RegExp("^\\d{2}-\\d{2}-\\d{4}")
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [DATE_OF_BIRTH, {
                        ...dob,
                        value: action.value,
                        error: !action.value.match(dobRegex) && dob && dob.errorMessage,
                    }]
                ])
            };
        }
        case ZIP_CODE: {
            const zipCode = state.fields.get(ZIP_CODE);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [ZIP_CODE, {
                        ...zipCode,
                        value: action.value,
                        error: !isPostalCode(action.value, "PL") && zipCode && zipCode.errorMessage,
                    }]
                ])
            };
        }
        case EMAIL: {
            const email = state.fields.get(EMAIL);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [EMAIL, {
                        ...email,
                        value: action.value,
                        error: !isEmail(action.value) && email && email.errorMessage,
                    }]
                ])
            };
        }
        case PHONE: {
            const phone = state.fields.get(PHONE);
            return {
                ...state,
                fields: new Map([
                    ...state.fields,
                    [PHONE, {
                        ...phone,
                        value: action.value,
                        error: !isMobilePhone(action.value, "pl-PL") && phone && phone.errorMessage,
                    }]
                ])
            };
        }
        case EDUCATION: {
            const edu = state.cvQuestions.get(EDUCATION);
            return {
                ...state,
                cvQuestions: new Map([
                    ...state.cvQuestions,
                    [EDUCATION, {
                        ...edu,
                        value: action.value,
                        error: action.value.length < 5 && edu && edu.errorMessage,
                    }]
                ])
            };
        }
        case EDUCATION_YEAR: {
            const edu = state.cvQuestions.get(EDUCATION_YEAR);
            return {
                ...state,
                cvQuestions: new Map([
                    ...state.cvQuestions,
                    [EDUCATION_YEAR, {
                        ...edu,
                        value: action.value,
                        error: action.value.length < 5 && edu && edu.errorMessage,
                    }]
                ])
            };
        }
        case FIND_OUT: {
            const findOut = state.openQuestions.get(FIND_OUT);
            return {
                ...state,
                openQuestions: new Map([
                    ...state.openQuestions,
                    [FIND_OUT, {
                        ...findOut,
                        value: action.value,
                        error: action.value.length < 5 && findOut && findOut.errorMessage,
                    }]
                ])
            };
        }
        case HOBBY: {
            const hobby = state.openQuestions.get(HOBBY);
            return {
                ...state,
                openQuestions: new Map([
                    ...state.openQuestions,
                    [HOBBY, {
                        ...hobby,
                        value: action.value,
                        error: action.value.length < 5 && hobby && hobby.errorMessage,
                    }]
                ])
            };
        }
        case SKILLS: {
            const skills = state.openQuestions.get(SKILLS);
            return {
                ...state,
                openQuestions: new Map([
                    ...state.openQuestions,
                    [SKILLS, {
                        ...skills,
                        value: action.value,
                        error: action.value.length < 5 && skills && skills.errorMessage,
                    }]
                ])
            };
        }
        case MOTIVATION: {
            const mot = state.openQuestions.get(MOTIVATION);
            return {
                ...state,
                openQuestions: new Map([
                    ...state.openQuestions,
                    [MOTIVATION, {
                        ...mot,
                        value: action.value,
                        error: action.value.length < 5 && mot && mot.errorMessage,
                    }]
                ])
            };
        }
        case SOFT_SKILLS: {
            const soft = state.openQuestions.get(SOFT_SKILLS);
            return {
                ...state,
                openQuestions: new Map([
                    ...state.openQuestions,
                    [SOFT_SKILLS, {
                        ...soft,
                        value: action.value,
                        error: action.value.length < 5 && soft && soft.errorMessage,
                    }]
                ])
            };
        }
        case SET_YES_NO: {
            const newYesNo = [...state.yesNoQuestions];
            newYesNo[action.value.index].value = action.value.ticked;
            return {
                ...state,
                yesNoQuestions: newYesNo,
            };
        }
        case SET_WEEK_DAY: {
            const newWeekDays = [...state.weekDays];
            newWeekDays[action.value.index].value = action.value.ticked;
            return {
                ...state,
                weekDays: newWeekDays,
            };
        }
        case SET_AGREEMENT: {
            const newAgreements = [...state.agreements];
            newAgreements[action.value.index].value = action.value.ticked;
            return {
                ...state,
                agreements: newAgreements,
            };
        }
        case CV_FILE: {
            return {
                ...state,
                cvFile: action.value,
            };
        }
        case VALIDATE_FIRST_STEP: {
            return {
                ...state,
                isFirstStepValidated: true,
            };
        }
        case VALIDATE_SECOND_STEP: {
            return {
                ...state,
                isSecondStepValidated: true,
            };
        }
        default:
            return state;
    }
};

const INITIAL_FORM_STATE: FormStateType = {
    fields: new Map([
        [FIRST_NAME,
        {
            label: "Imię*",
            value: "",
            errorMessage: "Wprowadź poprawne imię",
            error: "Wprowadź poprawne imię",
        }],
        [STREET,
        {
            label: "Ulica i numer*",
            value: "",
            errorMessage: "Wprowadź poprawną nazwę ulicy i numer",
            error: "Wprowadź poprawną nazwę ulicy i numer",
        }],
        [LAST_NAME,
        {
            label: "Nazwisko*",
            value: "",
            errorMessage: "Wprowadź poprawne nazwisko",
            error: "Wprowadź poprawne nazwisko",
        }],
        [CITY,
        {
            label: "Miasto*",
            value: "",
            errorMessage: "Wprowadź poprawną nazwę miasta",
            error: "Wprowadź poprawną nazwę miasta",
        }],
        [DATE_OF_BIRTH,
            {
            label: "Data urodzenia*",
            value: "",
            errorMessage: "Wprowadź poprawną datę urodzenia",
            error: "Wprowadź poprawną datę urodzenia",
            placeholder: "DD-MM-RRRR",
        }],
        [ZIP_CODE,
            {
            label: "Kod pocztowy*",
            value: "",
            errorMessage: "Wprowadź poprawny kod pocztowy",
            error: "Wprowadź poprawny kod pocztowy",
            placeholder: "__-___",
        }],
        [EMAIL,
            {
            label: "Adres e-mail*",
            value: "",
            errorMessage: "Wprowadź poprawny adres email",
            error: "Wprowadź poprawny adres email",
        }],
        [PHONE,
            {
            label: "Numer telefonu*",
            value: "",
            errorMessage: "Wprowadź poprawny numer telefonu",
            error: "Wprowadź poprawny numer telefonu",
        }],

    ]),
    cvQuestions: new Map([
        [EDUCATION,
            {
                label: "Gdzie się uczysz/pracujesz?*",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
        }],
        [EDUCATION_YEAR,
            {
                label: "Kierunek i rok studiów*",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
            }],
    ]),
    openQuestions: new Map([
        [FIND_OUT,
            {
                label: "Jak dowiedziałeś się o Fundacji?*",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
            }],
        [HOBBY,
            {
                label: "Jakie są Twoje zainteresowania?*",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
            }],
        [SKILLS,
            {
                label: "Jakie są Twoje umiejętności, które mogłabyś/mógłbyś wykorzystać na naszych oddziałach?* (np. granie na gitarze, śpiew, zdolności plastyczne, teatralne...)",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
            }],
        [MOTIVATION,
            {
                label: "Dlaczego chcesz zostać wolntariuszem naszej fundacji?*",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
            }],
        [SOFT_SKILLS,
            {
                label: "Które Twoje cechy ułatwią Ci pracę z dziećmi chorymi onkologicznie?*",
                value: "",
                errorMessage: "Uzupełnij pytanie",
                error: "Uzupełnij pytanie",
            }],
    ]),
    yesNoQuestions: [
        {
            label: "Czy pracowałaś/pracowałeś już wcześniej jako wolontariusz?*",
            value: true,
            errorMessage: "Uzupełnij pytanie",
            error: "Uzupełnij pytanie",
        },
        {
            label: "Czy miałaś/miałeś kontakt z osobami chorymi onkologicznie?*",
            value: true,
            errorMessage: "Uzupełnij pytanie",
            error: "Uzupełnij pytanie",
        }
    ],
    agreements: [
        {
            label: "Wyrażam zgodę na umieszczenie moich danych w bazie wolontariuszy Fundacji Pomocy Dzieciom\n" +
                "z Chorobami Nowotworowymi w Poznaniu.",
            value: false,
            errorMessage: "Uzupełnij zgody",
            error: "Uzupełnij zgody",
        },
    ],
    weekDays: ["Pn", "Wt", "Śr", "Czw", "Pt", "Sob", "Ndz"].map( day => (
        {
            label: day,
            value: false,
            errorMessage: "Zaznacz przynajmniej jeden dzień tygodnia",
            error: "Zaznacz przynajmniej jeden dzień tygodnia",
        })
    ),
    cvFile: null,
    setter: (data: ActionType) => {},
    isFirstStepValidated: false,
    isSecondStepValidated: false,
}

export const CvFormContextProvider = ({children} : {children: ReactNode} ) => {
    const [cvFormState, dispatch] = useReducer(cvFormReducer, INITIAL_FORM_STATE);

    const contextValue: FormStateType = {
        ...cvFormState,
        setter: dispatch,
    };
    return <CvFormContext.Provider value={contextValue}>{children}</CvFormContext.Provider>;
};

const CvFormContext = createContext<FormStateType>({
    ...INITIAL_FORM_STATE,
});

export default CvFormContext;
