import {Grid} from "@mui/material";
import SingleItem from "./SingleItem";

type Props = {
    content: any;
}

const ImageWithTitleDescription = ({content}: Props) => {

    return (
        <Grid container item direction="column" justifyContent="center" alignItems="center" xs={12} lg={10} xl={8} sx={{margin: 'auto'}} pt={4} pb={10}>
            {content.content.map((item: any, index: number) => <SingleItem key={index} item={item} />)}
        </Grid>
    )
}

export default ImageWithTitleDescription;
