import {Box, Grid, IconButton, styled, Typography, useTheme} from "@mui/material";
import {WPImage} from "../model/WPImage";
import {useState} from "react";
import SectionTitle from "../ui/SectionTitle";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import large_bubble from "../assets/images/small-dark-half-bubble.png";
import small_bubble from "../assets/images/tiny-dark-bubble.png";


type BubbleProps = {
    xl?: boolean;
    top: any;
    horizontal: any;
}

const Bubble = ({xl, top, horizontal}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: horizontal.left, right: horizontal.right}}>
        <img src={xl ? large_bubble  : small_bubble} alt={"bubble_background"}/>
    </Box>
}

const SliderImage = styled("img")(({theme}) => ({
    [theme.breakpoints.up("xl")]: {
        width: "1175px",
        height: "auto",
    },
    [theme.breakpoints.down("xl")]: {
        maxWidth: "90%",
        height: "auto"
    },
}))

type Props = {
    content: {
        title: string;
        slides: {slide: WPImage}[];
        background: "blank" | "dark_bubbles";
    };
}

type SliderButtonProps = {
    onClick: () => void;
    fwd: boolean;
}

const SliderButton = ({onClick, fwd}: SliderButtonProps) => {
    const {palette} = useTheme();

    return <IconButton
        onClick={onClick}
        sx={{
        backgroundColor: palette.custom.greenDarkTextBlack,
        "&:hover": {
            backgroundColor: palette.custom.greenDarkTextBlack,
        },
        color: "white",
        padding: {xl: "25px", md: "20px", xs: "15px"},
        mr: "32px",
    }}>
        {fwd ?
            <ArrowForwardIosIcon/>
        :
            <ArrowBackIosIcon/>
        }
    </IconButton>
}

const Slider = ({content}: Props) => {
    const {palette} = useTheme();
    const [index, setIndex] = useState(0);

    const fwdSlide = () => {
        setIndex(index == content.slides.length - 1 ? 0 : index + 1);
    }

    const prevSlide = () => {
        setIndex(index == 0 ? content.slides.length - 1 : index - 1);
    }

    const addBackground = () => {
        switch(content.background) {
            case "dark_bubbles": {
                return <>
                    <Bubble top={"100px"} horizontal={{left: "0"}} xl/>
                    <Bubble top={"60%"} horizontal={{right: "50px"}}/>
                </>
            }
            default:
                return <></>
        }
    }

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", position:"relative", backgroundColor: palette.custom.white}}>
        <SectionTitle title={content.title}/>
        {addBackground()}
        <Box sx={{position: "relative", mb: "130px", display: "flex", justifyContent: "center"}}>
            <SliderImage src={content.slides[index].slide.sizes.large} alt={content.slides[index].slide.alt}/>
            {content.slides.length > 1 ?
                <Box sx={{position: "absolute", bottom: "-30px", right: "100px"}}>
                    <SliderButton fwd={false} onClick={fwdSlide}/>
                    <SliderButton fwd={true} onClick={prevSlide}/>
                </Box>
            : ''}
        </Box>
    </Grid>
    }

export default Slider;
