import {deleteAuthRequest, postAuthRequest, putAuthRequest} from "./api";

export const updateUser = async (user: any, token: string, redirectToLogin: () => void) => {
    return await putAuthRequest(`/ex/users`, user, token, redirectToLogin);
}

export const createUser = async (user: any, token: string, redirectToLogin: () => void) => {
    return await postAuthRequest(`/ex/users`, user, token, redirectToLogin);
}

export const deleteUser = async (id: number, token: string, redirectToLogin: () => void) => {
    return await deleteAuthRequest(`/ex/users/${id}`, token, redirectToLogin);
}