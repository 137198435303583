import axios from "axios";

export const sendLoginRequest = async (login: string, password: string, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/jwt-auth/v1/token" : "";

    const formData = new FormData();
    formData.append("username", login);
    formData.append("password", password);

    const config = {
        headers: {
        }
    }
    try {
        const response = await axios.post(url, formData, config)
        if (response.status != 200) {
            onError && onError("Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.");
        }
        return response.data.data;
    } catch(error: any) {
        console.error(error.response? error.response : error);
        if (error.response.status === 403) {
            onError && onError("Nieprawidłowy login lub hasło");
        } else {
            onError && onError("Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.");
        }
    }
    return null;
}

export const sendResetPassword = async (email: string, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/account/password/reset" : "";

    const formData = new FormData();
    formData.append("email", email);

    const config = {
        headers: {
        }
    }
    try {
        const response = await axios.post(url, formData, config)
        if (response.status != 200) {
            console.error(response);

        }
        return response.data;
    } catch(error: any) {
        console.error(error.response? error.response : error);
    }
    return null;
}
