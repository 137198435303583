import {Box, Divider, Grid, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import MailIcon from "../ui/icons/MailIcon";
import PhoneIcon from "../ui/icons/PhoneIcon";
import SavingIcon from "../ui/icons/SavingIcon";
import FacebookIcon from "../ui/icons/FacebookIcon";
import InstagramIcon from "../ui/icons/InstagramIcon";
import YouTubeIcon from "../ui/icons/YouTubeIcon";
import TikTokIcon from "../ui/icons/TikTok";
import {useContext} from "react";
import ContentContext from "../store/content-context";

const Footer = () => {
    const {palette} = useTheme();
    const {foundation} = useContext(ContentContext);

    return <Box sx={{height: {xs: "100%", lg: "557px"}, width: "100%", backgroundColor: palette.custom.greenDarkTextBlack}}>
        <Grid container direction={"column"} sx={{width: {xs: "90%", lg: "1175px"} , pt: "96px", pb: "96px", height: "100%", m: "0 auto"}}>
            <Box sx={{display: "flex", flexDirection: {xs: "column", lg: "row"}, wrap:"nowrap",  m: {xs: "0 auto", lg: "0"}}}>
                <Grid item container direction={"column"} alignItems={{xs: "center", lg: "start"}}>
                    <Box sx={{height: "60px"}}>
                        <MailIcon/>
                    </Box>
                    <Typography variant={"body2"} color={"white"} mb={"20px"} textAlign={{xs: "center", lg: "left"}}>{foundation.name}</Typography>
                    <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>{foundation.streetAndNumber}</Typography>
                    <Typography variant={"body2"} color={"white"} mb={"20px"} textAlign={{xs: "center", lg: "left"}}>{foundation.postalCode} {foundation.city}</Typography>
                    <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>NIP: {foundation.nip}</Typography>
                </Grid>
                <Grid item container direction={"column"} mt={{xs: "50px", lg: 0}} alignItems={{xs: "center", lg: "start"}}>
                    <Box sx={{height: "60px", }}>
                        <PhoneIcon />
                    </Box>
                    {foundation.phones && foundation.phones[0] && <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>tel. {foundation.phones[0]}</Typography>}
                    {foundation.phones && foundation.phones.slice(1).map((phone: string, index: number) =>
                        <Typography key={index} variant={"body2"} color={"white"} sx={{ml: "30px"}} textAlign={{xs: "center", lg: "left"}}>{phone}</Typography>
                    )}
                </Grid>
                <Grid item container direction={"column"} mt={{xs: "50px", lg: 0}} alignItems={{xs: "center", lg: "start"}}>
                    <Box sx={{height: "60px"}}>
                        <SavingIcon />
                    </Box>
                    <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>{foundation.bank}</Typography>
                    <Typography variant={"body2"} color={"white"} mb={"20px"} textAlign={{xs: "center", lg: "left"}}>{foundation.account}</Typography>
                    <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>KONTO EURO PL</Typography>
                    <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>{foundation.euroPlAccount}</Typography>
                    <Typography variant={"body2"} color={"white"} mb={"20px"} textAlign={{xs: "center", lg: "left"}}>kod SWIFT (BIC) {foundation.swiftCode}</Typography>
                    <Typography variant={"body2"} color={"white"} textAlign={{xs: "center", lg: "left"}}>KRS: {foundation.krs}</Typography>
                </Grid>
                <Grid item container justifyContent={{xs: "center", lg: "end"}} alignItems={"start"}  mt={{xs: "100px", lg: 0}} mb={{xs: "50px", lg: 0}}>
                    {foundation.facebookLink !== "" && <IconButton href={foundation.facebookLink} target={"_blank"}>
                        <FacebookIcon/>
                    </IconButton>}
                    {foundation.instagramLink !== "" && <IconButton href={foundation.instagramLink} target={"_blank"}>
                        <InstagramIcon />
                    </IconButton>}
                    {foundation.youtubeLink !== "" && <IconButton href={foundation.youtubeLink} target={"_blank"}>
                        <Box component={'span'} mt={-0.1}><YouTubeIcon/></Box>
                    </IconButton>}
                    {foundation.tiktokLink !== "" && <IconButton href={foundation.tiktokLink} target={"_blank"}>
                        <TikTokIcon />
                    </IconButton>}
                </Grid>
            </Box>
            <Grid item sx={{mt: "auto"}}>
                <Divider sx={{backgroundColor: "white"}}/>
                <Typography variant={"body2"} color={"white"} sx={{mt: "17px", textAlign: {xs: "center", lg: "left"}}}>© Copyright by {foundation.name} w {foundation.city} 2022. Design & code by Expansja Advertising</Typography>
                <Typography variant={"body2"} color={"white"} sx={{mt: "17px", textAlign: {xs: "center", lg: "left"}}}><Box component={'a'} sx={{color: 'white', fontSize: '1rem', textDecoration: 'none'}} target={'_blank'} href="https://www.e-pity.pl">Program e-pity Copyright 2023-2024 e-file sp. z o.o. sp. k.</Box> lub <Box component={'a'} target={'_blank'} sx={{color: 'white', fontSize: '1rem', textDecoration: 'none'}} title="Program e-pity 2023-2024" href="https://www.e-pity.pl">program e-pity by e-file</Box></Typography>
            </Grid>
        </Grid>
    </Box>
}

export default Footer;
