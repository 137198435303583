import axios from "axios";
import {CheckboxType, FieldType} from "../components/CvForm/cv-form-context";

export const sendCv = async (fields: Map<string, FieldType>, cvQuestions: Map<string, FieldType>, openFields: Map<string, FieldType>, yesNoQuestions: CheckboxType[], weekDays: CheckboxType[], file: File | null, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/cv" : "";

    const personalData = [] as any[];
    const cvQuestionsData = [] as any[];
    const openQuestions = [] as any[];
    const yesNoQuestionsData = [] as any[];
    const weekDaysData = [] as any[];

    fields.forEach(({label, value}) => {
        personalData.push({fieldName: label, value: value})
    })
    cvQuestions.forEach(({label, value}) => {
        cvQuestionsData.push({fieldName: label, value: value})
    })
    openFields.forEach(({label, value}) => {
        openQuestions.push({fieldName: label, value: value})
    })
    yesNoQuestions.forEach(({label, value}) => {
        yesNoQuestionsData.push({fieldName: label, value: value})
    })
    weekDays.forEach(({label, value}) => {
        weekDaysData.push({fieldName: label, value: value})
    })

    const formData = new FormData();
    formData.append("personalData", JSON.stringify(personalData));
    formData.append("cvQuestions", JSON.stringify(cvQuestionsData));
    formData.append("openQuestions", JSON.stringify(openQuestions));
    formData.append("yesNoQuestions", JSON.stringify(yesNoQuestionsData));
    formData.append("weekDays", JSON.stringify(weekDaysData));
    if(file) formData.append("file", file);

    const config = {
        headers: {
            "Content-Type":'multipart/form-data'
        }
    }
    try {
        const response = await axios.post(url, formData, config)
        if (response.status != 200) {
            onError && onError("Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.");
        }
    } catch(error: any) {
        console.error(error.response? error.response : error);
        onError && onError("Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.");
    }
}

export const sendMail = async (data: {}, onError: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + "/ex/mail" : "";

    const config = {
        headers: {
            'Content-type': 'application/json',
        }
    }

    try {
        await axios.post(url, data, config)
    } catch(error: any) {
        onError && onError('Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.')
    }
}
