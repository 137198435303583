import {useTheme} from "@mui/material";

const NumberOneCircle = () => {
    const {palette} = useTheme();
    return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.7969 34.1562V38H21.8906V34.1562H26.1719V23.2188C25.5469 23.7396 24.8698 24.1354 24.1406 24.4062C23.4115 24.6771 22.724 24.8854 22.0781 25.0312V20.8125C23.1198 20.5 24.1302 20.0104 25.1094 19.3438C26.1094 18.6771 26.9427 17.9167 27.6094 17.0625H30.4844V34.1562H34.7969Z" fill={palette.custom.whiteText}/>
        <circle cx="28" cy="28" r="26.5" stroke="#AACE38" strokeWidth="3"/>
    </svg>
}

export default NumberOneCircle;
