import {useTheme} from "@mui/material";

const NumberThreeCircle = () => {
    const {palette} = useTheme();
    return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.9531 16.5938C28.4323 16.5938 29.7344 16.8542 30.8594 17.375C32.0052 17.8958 32.901 18.6146 33.5469 19.5312C34.2135 20.4271 34.5469 21.4583 34.5469 22.625C34.5469 23.5625 34.3385 24.3646 33.9219 25.0312C33.5052 25.6771 32.9844 26.1979 32.3594 26.5938C31.7344 26.9896 31.099 27.25 30.4531 27.375C31.099 27.5208 31.7344 27.8021 32.3594 28.2188C32.9844 28.6146 33.5052 29.1458 33.9219 29.8125C34.3385 30.4583 34.5469 31.25 34.5469 32.1875C34.5469 33.4167 34.2031 34.5 33.5156 35.4375C32.849 36.375 31.9427 37.1146 30.7969 37.6562C29.651 38.1979 28.3594 38.4688 26.9219 38.4688C25.7969 38.4688 24.7448 38.3021 23.7656 37.9688C22.8073 37.6146 22.0469 37.1979 21.4844 36.7188V32.625C22.1927 33.1042 22.9323 33.5625 23.7031 34C24.474 34.4167 25.3906 34.625 26.4531 34.625C27.5156 34.625 28.4115 34.375 29.1406 33.875C29.8698 33.375 30.2344 32.625 30.2344 31.625C30.2344 31.0417 30.0469 30.5625 29.6719 30.1875C29.2969 29.7917 28.8073 29.5 28.2031 29.3125C27.6198 29.1042 26.9948 29 26.3281 29H24.6094V25.625H26.3281C27.4115 25.625 28.3281 25.4688 29.0781 25.1562C29.849 24.8438 30.2344 24.2396 30.2344 23.3438C30.2344 22.4271 29.8385 21.7188 29.0469 21.2188C28.276 20.6979 27.3698 20.4375 26.3281 20.4375C25.4323 20.4375 24.6406 20.625 23.9531 21C23.2656 21.3542 22.6302 21.8229 22.0469 22.4062V18.3438C22.5885 17.8438 23.276 17.4271 24.1094 17.0938C24.9427 16.7604 25.8906 16.5938 26.9531 16.5938Z" fill={palette.custom.whiteText}/>
        <circle cx="28" cy="28" r="26.5" stroke="#AACE38" strokeWidth="3"/>
    </svg>
}

export default NumberThreeCircle;
