import {Box} from "@mui/material";

type BubbleProps = {
    src: string;
    top: any;
    left: any;
}

const Bubble = ({src, top, left}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: left}}>
        <img src={src} alt={"bubble_background"}/>
    </Box>
}

export default Bubble;