import {ReactNode, useContext} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import {Alert, Box, Snackbar} from "@mui/material";
import SnackbarContext from "../store/snackbar-context";
import FloatingBanner from "../components/FloatingBanner";
import {useLocation} from "react-router-dom";
import {useCustomTheme} from "../store/theme-context";

const MainLayout = ({children}: {children: ReactNode}) => {
    const {isHighContrast} = useCustomTheme()
    const {msg, severity, setMsg} = useContext(SnackbarContext);
    const location = useLocation();
    const showBanner = location.pathname !== '/co-robimy/projekty/zaopiekowani';

    return (
        <>
            <Header/>
                <Box component={"main"} sx={{
                    '& a': {
                        textDecoration: isHighContrast ? 'underline' : 'inherit',
                    },
                    '& a:hover': {
                        border: isHighContrast ? '1px solid #000' : 'inherit',
                    },
                    '& a:focus': {
                        border: isHighContrast ? '1px solid #000' : 'inherit',
                    },
                }}>
                    {children}
                </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={msg != ""} onClose={() => setMsg({msg: ""})}>
                <Alert severity={severity}>{msg}</Alert>
            </Snackbar>
            {showBanner && <FloatingBanner/>}
            <Footer/>
        </>
    );
}

export default MainLayout;
