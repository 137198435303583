import Post from "../model/Post";
import {Box, Button, Grid, IconButton, Typography, useTheme} from "@mui/material";
import SectionTitle from "../ui/SectionTitle";
import ArrowRight from "../ui/icons/ArrowRight";
import {useNavigate} from "react-router-dom";
import GreenFacebookIcon from "../ui/icons/GreenFacebookIcon";
import GreenInstagramIcon from "../ui/icons/GreenInstagramIcon";
import {SubjectButton} from "./News";
import DOMPurify from "dompurify";
import Gallery from "./Gallery";
import {useCustomTheme} from "../store/theme-context";

type Props = {
    content: Post;
}

type NewsHeaderProps = {
    title: string;
}

const NewsHeader = ({title}: NewsHeaderProps) => {
    const {palette} = useTheme();
    const navigate = useNavigate();

    return <Grid container justifyContent={"flex-start"} flexDirection={{xs: 'column', sm: "row"}} alignItems={"center"} sx={{gap: {xs: '20px', sm: '0px'}, flexWrap: 'nowrap', width: {lg: "1175px", xs: "90%"}, m: "96px auto 96px auto", color: "transparent", position: "relative"}}>
        <Button sx={{
            zIndex: 5,
            textTransform: "none",
            fontWeight: 700,
            height: "44px",
            alignSelf: {xs: 'flex-start', sm: 'center'}
        }}
                startIcon={<ArrowRight left/>}
                onClick={() => navigate(-1)}
        >
            Wróć
        </Button>
        <SectionTitle title={title} margin={true} color={palette.custom.greenDarkTextBlack} />
    </Grid>
}

type NewsBannerProps = {
    content: Post;
}

const NewsBanner = ({content}: NewsBannerProps) => {
    const {palette} = useTheme();
    const {isHighContrast} = useCustomTheme();

    return <Grid item container wrap="wrap" sx={{width: "100%"}}>
        <Grid item container sx={{
            height: "629px",
            width: {xs: "100%", lg:"60%"},
            backgroundImage: `url(${content.image.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        }}
        alignItems={"end"}
        >
            <Box sx={{
                position: "absolute",
                top: "-43px",
                right: "100px",
                display: "flex",
                justifyContent: "space-between",
            }}>
                {content.facebook != "" && <IconButton sx={{mr: "14px"}} href={content.facebook} target={"_blank"}>
                    <GreenFacebookIcon/>
                </IconButton>}
                {content.instagram != "" && <IconButton href={content.instagram} target={"_blank"}>
                    <GreenInstagramIcon/>
                </IconButton>}
            </Box>
            <Grid item container flexDirection={"column"} justifyContent={"center"} sx={{backgroundColor: isHighContrast ? palette.custom.white : `${palette.grey[700]}99`, width: "55%", height: "153px", pl: "24px", mb: "5%"}}>
                <Typography color={"white"} variant={"h5"}  sx={{lineHeight: 1, mt: "10px"}}>{content.title}</Typography>
            </Grid>
        </Grid>
        <Grid item flexDirection={"column"} justifyContent={"end"} alignItems={"start"} sx={{display: "flex", pl: "24px", mt: "24px"}}>
            <Typography variant={"body1"}>Opublikowano</Typography>
            <Typography variant={"body1"} sx={{fontWeight: 700}}>{content.publishedDate.toLocaleDateString("pl")}r.</Typography>
            <SubjectButton disabled sx={{backgroundColor: palette.custom.greenDarkTextBlack, color: "white !important", ml: 0, mt: "44px"}}>{content.subject}</SubjectButton>
        </Grid>
    </Grid>
}

const NewsArticle = ({content}: Props) => {

    return <>
        <NewsHeader title={content.title}/>
        <NewsBanner content={content}/>
        <Grid item sx={{width: {lg: "1175px", xs: "90%"}, m: "48px auto 96px auto", color: "primary", zIndex: 1}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.description)}}/>
        {content.gallery ? <Gallery content={content.gallery} /> : ''}
    </>
}

export default NewsArticle;
