import {useTheme} from "@mui/material";

const NumberTwoCircle = () => {
    const {palette} = useTheme();
    return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.5781 16.5938C29.0156 16.5938 30.2656 16.8646 31.3281 17.4062C32.4115 17.9271 33.2552 18.6667 33.8594 19.625C34.4635 20.5625 34.7656 21.6667 34.7656 22.9375C34.7656 23.9792 34.599 24.9375 34.2656 25.8125C33.9531 26.6667 33.4948 27.5208 32.8906 28.375C32.2865 29.2083 31.5469 30.1042 30.6719 31.0625C29.8177 32.0208 28.8385 33.1146 27.7344 34.3438H34.9219V38H21.2656V36.25C23.2656 34.0833 24.9427 32.2604 26.2969 30.7812C27.6719 29.2812 28.7031 28 29.3906 26.9375C30.099 25.8542 30.4531 24.8958 30.4531 24.0625C30.4531 23 30.099 22.1771 29.3906 21.5938C28.7031 20.9896 27.8385 20.6875 26.7969 20.6875C25.8385 20.6875 24.901 20.9375 23.9844 21.4375C23.0677 21.9375 22.3177 22.5 21.7344 23.125V18.6875C22.3594 18.1458 23.1927 17.6667 24.2344 17.25C25.276 16.8125 26.3906 16.5938 27.5781 16.5938Z" fill={palette.custom.whiteText}/>
        <circle cx="28" cy="28" r="26.5" stroke="#AACE38" strokeWidth="3"/>
    </svg>
}

export default NumberTwoCircle;
