import {Grid, useTheme} from "@mui/material";
import {useState} from "react";
import AmountInput from "./AmountInput";
import PersonalDataInput from "./PersonalDataInput";
import PaymentConfirmation from "./PaymentConfirmation";
import Stepper from "../../ui/Stepper";
import OtherPayment from "./OtherPayment";

type PaymentFormType = {
    agreements: any[];
    otherPayment: any[];
}

const PaymentForm = ({agreements, otherPayment}: PaymentFormType) => {
    const {palette} = useTheme();
    const [index, setIndex] = useState(0);


    return <><Grid item container justifyContent={"center"}
                   sx={{minWidth: "100%", m: "0", backgroundColor: palette.secondary.light, position: "relative"}}>
        <Grid item container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{
            width: {lg: "1175px", xs: "90%"},
            m: "85px auto 85px auto",
            backgroundColor: "white",
            color: "black",
            position: "relative"
        }}>
            <Grid item container justifyContent={"center"}>
                <Stepper step={index} labels={["Typ i wysokość datku", "Dane osobowe", "Potwierdzenie"]}/>
            </Grid>
            {[<AmountInput onNext={() => setIndex(1)}/>,
                <PersonalDataInput agreements={agreements} onNext={() => setIndex(2)} onBack={() => setIndex(0)}/>,
                <PaymentConfirmation onBack={() => setIndex(1)}/>
            ][index]}
        </Grid>
    </Grid>
        {otherPayment.length > 0 && <OtherPayment other={otherPayment}/>}
    </>
}

export default PaymentForm;