import BlankLayout from "../layout/BlankLayout";
import {Divider, Grid, InputLabel, styled, Typography, useTheme} from "@mui/material";
import Logo from "../ui/Logo";
import {GreenButton} from "../ui/GreenButton";
import {StyledTextField} from "../ui/StyledTextfield";
import {useContext, useEffect, useState} from "react";
import isEmail from 'validator/lib/isEmail';
import SnackbarContext from "../store/snackbar-context";
import Bubble from "../ui/Bubble";
import darkBubble from "../assets/images/large-dark-half-bubble1.png";
import smallLightBubble from "../assets/images/tiny-light-bubble.png";
import largeLightBubble from "../assets/images/large-light-bubble.png";
import AuthContext from "../store/auth-context";
import {useNavigate} from "react-router-dom";
import {sendResetPassword} from "../api/auth-api";

const StyledLabel = styled(InputLabel)(({theme}) => ({
    fontWeight: 700,
    fontSize: "18px",
    paddingLeft: "20px",
    whiteSpace: "normal",
    height: "auto",
    color: theme.palette.primary.light
}))

const LoginPage = () => {
    const {palette} = useTheme();
    const {setMsg} = useContext(SnackbarContext);
    const [reset, setReset] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {login, token, isAdmin} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleClick = () => {
        setIsValidated(true);
        if(reset) {
            if(isEmail(username)){
                sendResetPassword(username, (error) => console.log(error))
                    .then(() =>
                        setMsg({msg: "Sprawdź swoją skrznkę pocztową", severity: "info"})
                    )
                    .catch(error =>
                        setMsg({msg: "Wystąpił błąd przy restowaniu hasła.", severity: "warning"})
                    )
            } else {
                setMsg({msg: "Wpisz poprawny adres email", severity: "warning"})
            }
        } else {
            if(username.length > 0 && password.length > 0){
                login(username, password);
            } else {
                setMsg({msg: "Wpisz poprawny login i hasło", severity: "warning"})
            }
        }
    }

    useEffect(()=> {
        if(token && isAdmin) {
            navigate('/accounts')
        } else if(token) {
            navigate('/account')
        }
    }, [token, isAdmin]);

    const changeForm = () => {
        setIsValidated(false);
        setUsername("");
        setReset(true);
    }

    return <BlankLayout>
        <Grid container sx={{width: "100%", height: "100vh", backgroundColor: palette.grey[100], position: "relative", overflow: "hidden"}}>
            <Grid container item sx={{width: "100%"}} flexDirection={"column"} alignItems={"center"}>
                <Grid item sx={{width: {lg: "1175px", xs: "90%"}, mt: "48px", zIndex: 2}}>
                    <Logo/>
                </Grid>
                <Grid item container flex={1} alignItems={"center"} flexDirection={"column"} justifyContent={"center"} sx={{zIndex: 2}}>
                    <Typography variant={"h5"} mb={"48px"} color={palette.custom.greenDarkTextBlack} textAlign={"center"} sx={{fontWeight: 700}}>
                        {reset ? "Resetuj hasło" : "Zaloguj się"}
                    </Typography>
                    <Grid item flexDirection={"column"} sx={{display: "flex", backgroundColor: "white", padding: "40px 80px", mb: "150px"}}>
                        <StyledLabel htmlFor={"login"}>{reset ? "Adres email" : "Login/email"}</StyledLabel>
                        <StyledTextField id={"login"} value={username} onChange={({target: {value}}) => setUsername(value)} error={username.length === 0 && isValidated}/>
                        {!reset && <><StyledLabel htmlFor={"password"} sx={{mt: "14px"}}>Hasło</StyledLabel>
                        <StyledTextField type="password"  id={"password"} value={password} onChange={({target: {value}}) => setPassword(value)} error={password.length === 0 && isValidated}/></>}
                        {reset && <Typography variant={"body2"} sx={{mt: "24px", color: palette.primary.main}}>Na Twój adres email zostanie wysłane nowe hasło</Typography>}
                        <GreenButton sx={{m: "24px 0"}} onClick={handleClick}>{reset ? "Wyślij" : "Zaloguj się"}</GreenButton>
                        {/*{!reset && <><Divider sx={{height: "2px"}}/>*/}
                        {/*<Typography variant={"body2"} sx={{mt: "24px", color: palette.primary.main, textDecoration: "underline", cursor: "pointer"}} onClick={() => changeForm()}>Nie pamiętasz hasła?</Typography></>}*/}
                    </Grid>
                </Grid>
                <Bubble src={darkBubble} top={"40%"} left={0}/>
                <Bubble src={smallLightBubble} top={"-70px"} left={"70%"}/>
                <Bubble src={smallLightBubble} top={"40%"} left={"85%"}/>
                <Bubble src={largeLightBubble} top={"60%"} left={"70%"}/>
            </Grid>
        </Grid>
    </BlankLayout>
}

export default LoginPage;
