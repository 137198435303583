import {Pagination, Grid, InputAdornment, styled, TextField, Typography, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from "react";
import {SubjectButton} from "./News";
import SearchIcon from "@mui/icons-material/Search";
import GuidesTile from "./GuidesTile";
import {useDebounce} from "use-debounce";

const SearchField = styled(TextField)(({theme}) => ({
    borderRadius: "25px",
    border: "none",
    backgroundColor: theme.palette.custom.grey,
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "none",
        },
    }
}))

const PaginationButton = styled(Pagination)(({theme}) => ({
    '& .MuiPaginationItem-page': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.custom.white,
        margin: '10px',
        minHeight: '50px',
        minWidth: '50px',
        borderRadius: '100%',
        fontSize: '22px',
        [theme.breakpoints.down('md')]: {
            minHeight: '40px',
            minWidth: '40px',
        },

        '&.Mui-selected': {
            backgroundColor: theme.palette.custom.greenDarkTextBlack,
            color: theme.palette.custom.whiteText,
        },
    },

    '& .MuiPaginationItem-previousNext': {
        margin: '10px',
        minHeight: '50px',
        minWidth: '50px',
        borderRadius: '100%',
        fontSize: '22px',
        backgroundColor: 'transparent !important',
        color: theme.palette.custom.greenDarkTextBlack,

        [theme.breakpoints.down('md')]: {
            minHeight: '40px',
            minWidth: '40px',
        },

        '&.Mui-disabled': {
            opacity: 0,
        },

        '& svg': {
            fontSize: '45px',

            [theme.breakpoints.down('md')]: {
                fontSize: '40px'
            },
        }
    }
}))

type Props = {
    content: any;
}

const Guides = ({content}: Props) => {
    const {palette} = useTheme();
    const [selectedSubject, setSelectedSubject] = useState(-1);
    const [guidesIndex, setGuidesIndex] = useState(5);
    const [prevIndex, setPrevIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const [searchValue] = useDebounce(searchInput, 1000);
    const [filteredGuides, setFilteredGuides] = useState([...content.guide]);

    const guides = content.guide.map((item: {}, index: number) => {
        return item
    })

    const allSubjectsGuides = content.guide.map((item: {tag: string}, index: number) => {
        return item.tag
    })

    const subjectsGuides = allSubjectsGuides.filter((value: {tag: string}, index: number, self: any) => {
        return self.indexOf(value) === index;
    })

    const [project, setProject] = useState([...subjectsGuides]);

    useEffect(() => {
        let guide;
        if(searchValue != "") {
            guide = content.guide.filter((item: {title: string, description: string}) => item.title.toLowerCase().includes(searchValue.toLowerCase()) || item.description.toLowerCase().includes(searchValue.toLowerCase()))
        } else {
            guide = content.guide
        }
        if(selectedSubject >= 0) {
            guide = guides.filter((item: { tag: any; }) => item.tag === project[selectedSubject])
        }
        setFilteredGuides(guide)
    }, [selectedSubject, searchValue])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        setGuidesIndex(value * 5)
        if(value === 1) {
            setPrevIndex(0)
        } else {
            setPrevIndex((5 * value) - 5)
        }
    };

    return (
        <Grid container alignItems="center" justifyContent="center" pb={10}>
            <Grid container item alignItems="center" justifyContent="space-between" flexDirection={{xs: "column", lg: "row"}} pt={10} pb={{xs: 4, lg: 6}} sx={{width: {lg: "1175px", xs: "90%"}}}>
                    <Grid item sx={{display: 'flex', alignItems: "center", flexWrap: 'wrap', rowGap: '6px', flexDirection: {xs: 'column', sm: 'row'}}}>
                        <Typography variant={"h6"} color="primary" sx={{fontSize: "24px", mr: '20px'}}>Projekt:</Typography>
                        {subjectsGuides.map((item: any, index: number) =>
                            <SubjectButton color={"secondary"} onClick={() => setSelectedSubject(selectedSubject === index ? -1 : index)}
                                           sx={{backgroundColor: selectedSubject === index ? palette.custom.greenDarkTextBlack : "white",
                                               color: selectedSubject === index ? "white" : palette.custom.greenDarkTextBlack,
                                               border: selectedSubject === index ? "none" : "1px solid",
                                               textTransform: 'uppercase',
                                               mt: {xs: 3, sm: 0}
                                           }} endIcon={selectedSubject === index ? <CloseIcon /> : ''}>
                                {subjectsGuides[index]}
                            </SubjectButton>)}
                    </Grid>
                <Grid container item alignItems={'center'} justifyContent={{xs: 'center', lg: "end"}} xs={8} pt={4}>
                    <SearchField placeholder={"Szukaj"} size={"small"} InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
                    }} onChange={(event) => setSearchInput(event.target.value)}/>
                </Grid>
            </Grid>
            {selectedSubject === -1 ? filteredGuides.slice(prevIndex, guidesIndex).map((guide: {title: string, img: {alt: string, url: string}, file: {url: string}, description: string, tag: string}, index: number) => {
                return (
                    <GuidesTile content={guide} key={index} />
                )
            }) : filteredGuides.map((guide: {title: string, img: {alt: string, url: string}, file: {url: string}, description: string, tag: string}, index: number) => {
                return (
                    <GuidesTile content={guide} key={index} />
                )})}
            <Grid container item alignItems={"start"} justifyContent={"end"} pt={6} pr={{xs: 0, lg: 2}} sx={{width: {xs: "90%", sm: '60%', lg: "1175px"}}}>
                {filteredGuides.length > 5 ? <PaginationButton count={Math.ceil(filteredGuides.length / 5)} page={page} size="large" color="secondary" onChange={handleChange} /> : ''}
            </Grid>
        </Grid>
    )
}

export default Guides;
