import {
    Step,
    StepConnector,
    Stepper as MuiStepper,
    stepConnectorClasses,
    StepIconProps,
    StepLabel,
    styled,
    useTheme
} from "@mui/material";
import {useCustomTheme} from "../store/theme-context";

const StepperConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },

    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        margin: "0 auto",
        width: "95%",
        backgroundColor: theme.palette.grey[400]
    },
}));

const StepperIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    fontSize: "24px",
    zIndex: 1,
    color: theme.palette.custom.whiteText,
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.custom.greenDarkTextBlack,
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.custom.greenDarkTextBlack,
    }),
}));

function StepperIcon(props: StepIconProps) {
    const {isHighContrast} = useCustomTheme();
    const {palette} = useTheme()
    const { active, completed, className } = props;

    const icons: { [index: string]: string } = {
        1: "1",
        2: "2",
        3: "3",
    };

    return (
        <StepperIconRoot ownerState={{ completed, active }} className={className} sx={{backgroundColor: isHighContrast ? palette.custom.white : '#ccc'}}>
            {icons[String(props.icon)]}
        </StepperIconRoot>
    );
}

type StepperProps = {
    step: number;
    labels: string[];
}

const Stepper = ({step, labels}: StepperProps) => {
    const {isHighContrast} = useCustomTheme();
    const {palette} = useTheme()
    return <MuiStepper activeStep={step} alternativeLabel connector={<StepperConnector/>} sx={{width: {xs:"90%", md:"70%"}, mt: "48px"}}>
        <Step key={0} sx={{color: palette.custom.main}}>
            <StepLabel StepIconComponent={StepperIcon} sx={{'& .MuiStepLabel-label': {color: palette.custom.main}}}>{labels[0]}</StepLabel>
        </Step>
        <Step key={1}>
            <StepLabel StepIconComponent={StepperIcon} sx={{'& .MuiStepLabel-label': {color: palette.custom.main}}}>{labels[1]}</StepLabel>
        </Step>
        <Step key={2}>
            <StepLabel StepIconComponent={StepperIcon} sx={{'& .MuiStepLabel-label': {color: palette.custom.main}}}>{labels[2]}</StepLabel>
        </Step>
    </MuiStepper>
}

export default Stepper;
