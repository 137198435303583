import {InputLabel, styled, TextField} from "@mui/material";

export const StyledTextField = styled(TextField)(({theme}) => ({
    '& .MuiInputBase-input': {
        fontSize: "18px",
        fontWeight: 700,
        color: "black",
        backgroundColor: "transparent",
    },
    '& .MuiOutlinedInput-root': {
        '& input:disabled ~ fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '& input:disabled ~ fieldset span': {
            color: theme.palette.primary.main,
        },
        '& fieldset': {
            border: "2px solid",
            borderColor: theme.palette.custom.greenDarkTextBlack,
        },
        '&:hover fieldset': {
            border: "2px solid",
            borderColor: theme.palette.custom.greenDarkTextBlack,
        },
        '&.Mui-focused fieldset': {
            border: "2px solid",
            borderColor: theme.palette.custom.greenDarkTextBlack,
        },
    },
}))

export const StyledLabel = styled(InputLabel)(({theme}) => ({
    fontWeight: 700,
    fontSize: "18px",
    paddingLeft: "20px",
    whiteSpace: "normal",
    height: "auto",
    color: theme.palette.primary.main,
}))
