import React from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestoreIcon from '@mui/icons-material/Restore';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {useCustomTheme} from "../../store/theme-context";

const WCAGMenu = () => {
	const { increaseFontSize, decreaseFontSize, resetFontSize, toggleContrast, isHighContrast } = useCustomTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-controls="wcag-menu"
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				aria-label="WCAG options"
				sx={{
					marginRight: {lg: '6px'},
					marginLeft: {xs: '6px', lg: 0}
				}}
			>
				<AccessibilityIcon fill={"#000"} />
			</IconButton>
			<Menu
				id="wcag-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					// 'aria-labelledby': 'wcag-button',
				}}
			>
				<MenuItem onClick={increaseFontSize}>
					<ZoomInIcon />
					<Typography variant="body1" ml={1}>
						Zwiększ czcionkę
					</Typography>
				</MenuItem>
				<MenuItem onClick={decreaseFontSize}>
					<ZoomOutIcon />
					<Typography variant="body1" ml={1}>
						Zmniejsz czcionkę
					</Typography>
				</MenuItem>
				<MenuItem onClick={resetFontSize}>
					<RestoreIcon />
					<Typography variant="body1" ml={1}>
						Resetuj czcionkę
					</Typography>
				</MenuItem>
				<MenuItem onClick={toggleContrast}>
					<Brightness4Icon />
					<Typography variant="body1" ml={1}>
						{isHighContrast ? 'Domyślny kontrast' : 'Wysoki kontrast'}
					</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default WCAGMenu;
