const SmallGreenPhoneIcon = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#3D8D4F"/>
        <g clipPath="url(#clip0_0_1)">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7975 10.2099C10.7427 10.3771 10.6919 10.5465 10.6452 10.7181C10.6426 10.7271 10.6401 10.7362 10.6376 10.7453C10.6363 10.7502 10.6349 10.7551 10.6336 10.76C10.6299 10.7738 10.6263 10.7876 10.6229 10.8014C9.17977 16.2738 12.408 21.8871 17.8566 23.3962L17.942 23.419L17.9421 23.4188C19.4737 23.8292 21.0479 22.9203 21.4583 21.3887C21.5416 21.078 21.5705 20.7655 21.551 20.4614L17.4125 18.072C16.8548 18.3481 16.3886 18.8032 16.1052 19.3848C14.5084 18.1729 13.4599 16.3566 13.2089 14.3678C13.9961 14.423 14.7566 14.1486 15.3242 13.6404L15.3242 9.36527C14.9987 9.07293 14.6014 8.85082 14.1499 8.72985C12.8081 8.37031 11.4335 9.02339 10.8375 10.2206L10.7975 10.2099Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
            </clipPath>
        </defs>
    </svg>
}

export default SmallGreenPhoneIcon;
