import {WPImage} from "./WPImage";
import {getRelativeLink} from "../utils/string-tools";
import Page from "./Page";
import Block from "./Block";

class Post {
    title: string;
    path: string;
    subject: string | undefined;
    displayLastNews?: boolean;
    type: "news" | "event";
    publishedDate: Date;
    eventDate: Date | undefined;
    description: string;
    image: WPImage;
    facebook: string;
    instagram: string;
    gallery: [];

    constructor(title: string, path: string, subject: string, displayLastNews: boolean, type: "news" | "event", publishedDate: Date, eventDate: Date | undefined, description: string, image: WPImage, facebook: string, instagram: string, gallery: []) {
        this.title = title;
        this.path = path;
        this.subject = subject;
        this.displayLastNews = displayLastNews;
        this.type = type;
        this.publishedDate = publishedDate;
        this.eventDate = eventDate;
        this.description = description;
        this.image = image;
        this.facebook = facebook;
        this.instagram = instagram;
        this.gallery = gallery;
    }

    public static fromApiResponse(response: any) {
        return new Post (
            response.acf.title,
            getRelativeLink(response.link),
            response._embedded["wp:term"][0][0] && response._embedded["wp:term"][0][0].name,
            response.acf.display_last_news,
            response.acf.type,
            new Date(response.date),
            response.acf.event_date && new Date(response.acf.event_date),
            response.acf.description,
            response.acf.image,
            response.acf.facebook,
            response.acf.instagram,
            response.acf.gallery
        )
    }

    public toPage(): Page{
        return new Page(
            this.path,
            this.title,
            [new Block("news_article", this)]
        )
    }
}

export default Post;
