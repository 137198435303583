import {Box, Grid, useTheme} from "@mui/material";
import large_bubble from "../assets/images/large-dark-half-bubble.png";
import small_bubble from "../assets/images/small-dark-half-bubble.png";
import DOMPurify from 'dompurify';

type Props = {
    content: any;
}

type BubbleProps = {
    xl?: boolean;
    top: any;
    horizontal: any;
}

const Bubble = ({xl, top, horizontal}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: horizontal.left, right: horizontal.right}}>
        <img src={xl ? large_bubble  : small_bubble} alt={"bubble_background"}/>
    </Box>
}

const GreyBgText = ({content}: Props) => {
    const {palette} = useTheme();

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", backgroundColor: palette.grey[100], position: "relative"}}>
        <Grid item sx={{width: {lg: "1175px", xs: "90%"}, p: "96px 0", m: "0 auto", color: palette.primary.main, zIndex: 1}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.text)}}/>
        <Bubble top={"-50px"} horizontal={{left: "0"}}/>
        <Bubble top={"calc(100% - 300px)"} horizontal={{right: 0}} xl/>
    </Grid>
}

export default GreyBgText;