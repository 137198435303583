import {Helmet} from "react-helmet";
import React, {useEffect} from "react";

const CustomHead = () => {

    useEffect(() => {
        if (document && process.env.REACT_APP_ENV == 'prod') {
            const gtm = document.createElement('noscript');
            gtm.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PGS7LJGW"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`
            document.body.prepend(gtm)
        }

    }, []);


    return <>{process.env.REACT_APP_ENV == 'prod' && <Helmet>
        <script data-cookieconsent="ignore">
            {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                    dataLayer.push(arguments)
                }
                    gtag("consent", "default", {
                    ad_personalization: "denied",
                    ad_storage: "denied",
                    ad_user_data: "denied",
                    analytics_storage: "denied",
                    functionality_storage: "denied",
                    personalization_storage: "denied",
                    security_storage: "granted",
                    wait_for_update: 500
                });
                    gtag("set", "ads_data_redaction", true);
                    gtag("set", "url_passthrough", true);
                `
            }
        </script>
        <script>
            {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PGS7LJGW');
                `
            }
        </script>
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js"
                data-cbid="529bf895-8990-43ef-a463-5a3dc5d098ff" data-blockingmode="auto" type="text/javascript"/>
    </Helmet>}</>
}

export default CustomHead;