import {Grid, Box, IconButton} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useState} from "react";

type Props = {
    content: any;
}

type SliderButtonProps = {
    onClick: () => void;
    fwd: boolean;
}

const SliderButtonDark = ({onClick, fwd}: SliderButtonProps) => {

    return <IconButton
        onClick={onClick}
        sx={{
            backgroundColor: 'secondary.main',
            marginRight: '60px',
            "&:hover": {
                backgroundColor: 'secondary.main',
            },
            color: 'white',
            padding: {xl: "25px", md: "20px", xs: "15px"},
        }}>
        {fwd ?
            <ArrowForwardIosIcon/>
            :
            <ArrowBackIosIcon/>
        }
    </IconButton>
}

const ImageSlider = ({content}: Props) => {

    const [index, setIndex] = useState(0);

    const next = () => {
        setIndex(index === -10 ? 10 : index - 1)
    }

    const prev = () => {
        setIndex(index === 10 ? -10 : index + 1);
    }

    return (
        <Grid container justifyContent="center" alignItems="center" pt={{xs: 15, lg: 30}} pb={{xs: 15, lg:30}} sx={{overflow: 'hidden'}}>
            <Grid container item justifyContent="center" alignItems="center" xs={12}>
                <Box component="img"
                     sx={{
                         transform: {xs: `translateX(${index * 250}px)`, lg: `translateX(${index * 200}px)`},
                         transition: 'all .5s'
                     }}
                     alt={content.img.alt}
                    src={content.img.url}
                />
            </Grid>
            <Grid container item justifyContent={{xs: 'center', lg: "flex-end"}} alignItems="center" pt={10} ml={{xs: '60px', lg: 0}} pr={{xs: 0, lg: 16}}>
                <Grid item sx={{display: 'flex', alignItems: "center", justifyContent: 'space-between'}}>
                    <SliderButtonDark fwd={false} onClick={prev}/>
                    <SliderButtonDark fwd={true} onClick={next}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ImageSlider;
