import {Box, IconButton, InputAdornment, styled, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useEffect, useState} from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SearchField = styled(TextField)(({theme}) => ({
	borderRadius: "25px",
	border: "none",
	backgroundColor: theme.palette.grey[100],
	marginBottom: '20px',
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: "none",
		},
	},
	[theme.breakpoints.down('sm')]: {
		width: '220px'
	},
}))

const SearchPageField = () => {
	const [searchInput, setSearchInput] = useState("");
	const [foundElements, setFoundElements] = useState<HTMLElement[]>([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (!searchInput) {
			foundElements.forEach((el, idx) => {
				if(el) {
					el.style.backgroundColor = '';
				}
			});
			setFoundElements([]);
			setCurrentIndex(0);
			return;
		}

		const elements = document.querySelectorAll<HTMLElement>('p, h1, h2, h3, h4, h5, h6, li');
		const found: HTMLElement[] = [];

		elements.forEach((el, idx) => {
			if(el.innerText) {
				if (el.innerText.toLowerCase().includes(searchInput.toLowerCase())) {
					found.push(el);
				}
			}
		});

		foundElements.forEach((el) => {
			if (el) {
				el.style.backgroundColor = '';
			}
		});

		setFoundElements(found);
		setCurrentIndex(0);
	}, [searchInput]);

	const handleNextResult = () => {
		if (foundElements.length > 0) {
			foundElements.forEach((el, idx) => {
				if(el) {
					el.style.backgroundColor = '';
				}
			});

			setCurrentIndex((prevIndex) => {
				const targetElement = foundElements[prevIndex];
				if (targetElement) {
					targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
					targetElement.style.backgroundColor = 'yellow';
				}

				return (prevIndex + 1) % foundElements.length;
			});
		}
	};

	return (
		<Box sx={{
			position: 'relative'
		}}>
			<SearchField placeholder={"Szukaj"} size={"small"} InputProps={{
				endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
			}} onChange={(event) => setSearchInput(event.target.value)}/>
			<IconButton
				onClick={handleNextResult}
				sx={{ position: "absolute", right: "-44px", top: "-1px" }}
			>
				<ArrowForwardIosIcon />
			</IconButton>
		</Box>
	)
}

export default SearchPageField
