const EditIcon = () => {
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.875 2.875L27.125 9.125C27.625 9.625 27.625 10.375 27.125 10.875L10.875 27.125C10.625 27.375 10.375 27.5 10 27.5H3.75C3 27.5 2.5 27 2.5 26.25V20C2.5 19.625 2.625 19.375 2.875 19.125L19.125 2.875C19.625 2.375 20.375 2.375 20.875 2.875ZM5 25H9.5L24.5 10L20 5.5L5 20.5V25Z" fill="#424242"/>
        <mask id="mask0_145_3002" maskUnits="userSpaceOnUse" x="2" y="2" width="26" height="26">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.875 2.875L27.125 9.125C27.625 9.625 27.625 10.375 27.125 10.875L10.875 27.125C10.625 27.375 10.375 27.5 10 27.5H3.75C3 27.5 2.5 27 2.5 26.25V20C2.5 19.625 2.625 19.375 2.875 19.125L19.125 2.875C19.625 2.375 20.375 2.375 20.875 2.875ZM5 25H9.5L24.5 10L20 5.5L5 20.5V25Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_145_3002)">
            <rect width="30" height="30" fill="#424242"/>
        </g>
    </svg>
}

export default EditIcon