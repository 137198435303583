import {ReactNode, useContext} from "react";
import {Alert, Box, Snackbar, useTheme} from "@mui/material";
import SnackbarContext from "../store/snackbar-context";
import {useCustomTheme} from "../store/theme-context";

const BlankLayout = ({children}: {children: ReactNode}) => {
    const {isHighContrast} = useCustomTheme()
    const {msg, severity, setMsg} = useContext(SnackbarContext);

    return (
        <>
            <Box component={"main"} sx={{
                '& a': {
                    textDecoration: isHighContrast ? 'underline' : 'inherit',
                },
                '& a:hover': {
                    border: isHighContrast ? '1px solid #000' : 'inherit',
                },
                '& a:focus': {
                    border: isHighContrast ? '1px solid #000' : 'inherit',
                },
            }}>
                {children}
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={msg != ""} onClose={() => setMsg({msg: ""})}>
                <Alert severity={severity}>{msg}</Alert>
            </Snackbar>
        </>
    );
}

export default BlankLayout;
