import {Grid, Box, styled, IconButton, Typography, Button, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownloadTile from '../../ui/DownloadTile';
import {useState} from "react";
import DOMPurify from "dompurify";

type Props = {
    item: {
        description: string,
        description_button: string,
        other_button: {
            files ?: [],
            link ?: string,
            option: string
        },
        img: {
            url: string,
            alt: string
        }
    }
}

const SingleItem = ({item}: Props) => {
    const {palette} = useTheme();
    const [activeDescription, setActiveDescription] = useState(true);
    const [activeFiles, setActiveFiles] = useState(false);

    const selectDescription = () => {
        activeDescription === false ? setActiveDescription(true) : setActiveDescription(false)
        activeFiles === true ? setActiveFiles(false) : setActiveFiles(true)
    }

    const selectFiles = () => {
        activeFiles === false ? setActiveFiles(true) : setActiveFiles(false)
        activeDescription === true ? setActiveDescription(false) : setActiveDescription(true)
    }

    const ButtonStyled = styled(IconButton) ({
        zIndex: 2,
        backgroundColor: palette.secondary.main,
        color: palette.custom.whiteText,
        border: 'none',
        borderRadius: '32px',
        textTransform: 'none',
        fontWeight: '700',
        fontSize: '17px',
        letterSpacing: '0.2px',
        maxWidth: '230px',
        marginBottom: '32px',
        "&:hover": {
            backgroundColor: 'rgb(42, 98, 55)'
        },
        ":disabled": {
            backgroundColor: palette.custom.greenDarkTextBlack,
            color: 'white',
        }
    }) as typeof IconButton

    return <Grid container item justifyContent={{xs: 'center', lg: 'flex-start'}} alignItems={'center'} pt={6}>
        <Grid item xs={12} sm={11} md={10} lg={12} sx={{display: 'flex', flexDirection: {xs: 'column',lg: 'row'}, alignItems: {xs: 'flex-start',lg: 'flex-end'}}}>
            <Box component="img" sx={{zIndex: 1, objectFit: 'cover', width: '100%', maxWidth: '676px'}} src={item.img.url} alt={item.img.alt} />
            <Grid item sx={{display: 'flex', flexDirection:  'column'}} mb={{xs: 0, lg: 8}} ml={{xs: 2, sm: 6, lg:-10}} mt={{xs: -4,md: -10, lg: 0}}>
                <ButtonStyled disabled={activeDescription} sx={{backgroundColor: palette.secondary.main,padding: {xs: '8px 25px', md: '15px 48px'}}} onClick={selectDescription}>{item.description_button}{!activeDescription ? <ArrowForwardIcon sx={{ml: 1}}/> : <ArrowDownwardIcon sx={{ml: 1}}/>}</ButtonStyled>
                {item.other_button.option === 'Materiały do pobrania' ? <ButtonStyled disabled={activeFiles} sx={{padding: {xs: '8px 25px', md: '15px 48px'}, marginBottom: 0, maxWidth: '322px'}} onClick={selectFiles}>{item.other_button.option}{!activeFiles ? <ArrowForwardIcon sx={{ml: 1}}/> : <ArrowDownwardIcon sx={{ml: 1}}/>}</ButtonStyled> : <Button href={item.other_button.link} color="secondary" variant="contained" sx={{
                    padding: {xs: '8px 25px', md: '15px 48px'},
                    zIndex: 2,
                    backgroundColor: palette.custom.greenDarkTextBlack,
                    color: 'white',
                    border: 'none',
                    borderRadius: '32px',
                    textTransform: 'none',
                    fontWeight: '700',
                    fontSize: '17px',
                    letterSpacing: '0.2px',
                    "&:hover": {
                        backgroundColor: 'rgb(42, 98, 55)'
                    }
                }}>{item.other_button.option}</Button>}
            </Grid>
        </Grid>
        {activeDescription &&
            <Grid item xs={11} md={10} lg={12} sx={{margin: '30px 0'}}>
                <Typography sx={{color: palette.primary.main}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.description)}}></Typography>
            </Grid>
        }
        {activeFiles &&
            <Grid item xs={11} md={10} lg={12} pt={6}>
                {item.other_button.files && item.other_button.files.map(({file}: any, index: number) =>
                    <DownloadTile key={index} title={file.title} url={file.url} subtype={file.subtype} />
                    )}
            </Grid>
        }
    </Grid>
}

export default SingleItem;
