import {Grid, Typography} from "@mui/material";
import {useContext, useState} from "react";
import PaymentDetails from "./PaymentDetails";
import TransferDetails from "./TransferDetails";
import PaymentForm from "./PaymentForm";
import PaymentContext from "./payment-context";

type Props = {
    content: any;
}

const PaymentSection = ({content}: Props) => {
    const {tabIndex: index} = useContext(PaymentContext);
    const [tabIndex, setTabIndex] = useState(index);

    return <>
        <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: {lg: "1175px", xs: "90%"}, m: "0 auto", color: "transparent", position: "relative"}}>
            <Grid item container justifyContent={"center"} mt={"96px"} mb={"96px"}>
                <Typography variant={"h5"} color={"primary"} sx={{m: "12px 24px", fontWeight: tabIndex == 0 ? 700 : 400, cursor: "pointer"}} onClick={()=> setTabIndex(0)}>Przekaż 1.5%</Typography>
                <Typography variant={"h5"} color={"primary"} sx={{m: "12px 24px", fontWeight: tabIndex == 1 ? 700 : 400, cursor: "pointer"}} onClick={()=> setTabIndex(1)}>Przelew na konto</Typography>
                <Typography variant={"h5"} color={"primary"} sx={{m: "12px 24px", fontWeight: tabIndex == 2 ? 700 : 400, cursor: "pointer"}} onClick={()=> setTabIndex(2)}>Wpłata online</Typography>
            </Grid>
        </Grid>
        {[<PaymentDetails content={content}/>, <TransferDetails content={content}/>, <PaymentForm agreements={content.agreements} otherPayment={content.other}/>][tabIndex]}
    </>
}

export default PaymentSection;
