import {Box, Grid} from "@mui/material";
import ueProject from "../assets/images/ue_project.png";
import wod from "../assets/images/wod.png"
import {NavLink} from "react-router-dom";

const FloatingBanner = () => {
    const maxWidth = {xs: '100px', md: '150px'}

    return <Grid
        item
        container
        flexDirection={'column'}
        alignItems={'end'}
        sx={{
            position: "fixed",
            right: 0,
            bottom: '10%',
            maxWidth: maxWidth,
            zIndex: 10000,
            boxShadow: 10,
            borderBottomLeftRadius: '20px',
            "& a": {
                display: 'flex'
            }
        }}
    >
        <NavLink to={'/co-robimy/projekty/zaopiekowani'}>
            <Box
                component={'img'}
                src={ueProject}
                alt={'Projekt Zaopiekowani'}
                sx={{
                    maxWidth: {xs: '100px', md: '149px'}
                }}
            />
        </NavLink>
        <a target={'_blank'} href={"https://wielkopolskaonkologiadziecka.pl/"}>
            <Box
                component={'img'}
                src={wod}
                alt={'Wielkopolska Onkologia Dziecięca'}
                sx={{
                    maxWidth: maxWidth
                }}
            />
        </a>
    </Grid>
}

export default FloatingBanner;