
type Props = {
    left?: boolean;
}
const ArrowRight = ({left} : Props) => {
    return <svg width="28" height="28" transform={left ? "scale(-1)" : "none" } viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.8258 14.9999H2V12.9999H22.8258L18.2407 7.6507L19.7593 6.34912L25.7593 13.3491C26.0802 13.7236 26.0802 14.2762 25.7593 14.6507L19.7593 21.6507L18.2407 20.3491L22.8258 14.9999Z" fill="black"/>
    </svg>

}

export default ArrowRight;
