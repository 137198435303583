import Foundation from "./Foundation";

class Settings {
    foundation: Foundation;

    private constructor(foundation: Foundation) {
        this.foundation = foundation;
    }

    public static fromApiResponse(response: any) {
        return new Settings(
            Foundation.fromApiResponse(response)
        )
    }
}

export default Settings;