import Block from "../model/Block";
import QuoteWithImage from "../components/QuoteWithImage";
import Tiles from "../components/Tiles";
import Slider from "../components/Slider";
import AvatarsList from "../components/AvatarsList";
import GreyBgText from "../components/GreyBgText";
import DownloadFiles from "../components/DownloadFiles";
import DownloadFilesGroup from "../components/DownloadFilesGroup";
import GreenBgText from "../components/GreenBgText";
import HeaderWithForm from '../components/HeaderWithForm';
import ImageWithText from "../components/ImageWithText";
import FoundationDetails from "../components/FoundationDetails";
import SimpleStatistics from "../components/SimpleStatistics";
import ImageWithTitleDescription from "../components/ImageWithTitleDescription";
import ImageWithTitle from "../components/ImageWithTitle";
import HeaderWithImage from "../components/HeaderWithImage";
import News from "../components/News";
import NewsArticle from "../components/NewsArticle";
import CollapsableContent from "../components/CollapsableContent/CollapsableContent";
import PaymentSection from "../components/PaymentForm/PaymentSection";
import WhiteBgText from "../components/WhiteBgText";
import CvForm from "../components/CvForm/CvForm";
import Calendar from "../components/Calendar";
import TitleWithText from "../components/TitleWithText";
import FancyCircle from "../components/FancyCircle";
import AvatarsListWithDescription from "../components/AvatarsListWithDescription";
import ImageSlider from "../components/ImageSlider";
import Guides from "../components/Guides";
import ContactDetails from "../components/ContactDetails";
import ContactForm from "../components/ContactForm";

const getComponents = (blocks: Block[]) => {
    return blocks.map((block, index) => {
            switch(block.component) {
                case "quote_with_image": return <QuoteWithImage key={index} content={block.content}/>
                case "header_with_form": return <HeaderWithForm key={index} content={block.content} />
                case "header_with_image": return <HeaderWithImage key={index} content={block.content} />
                case "image_with_text": return <ImageWithText key={index} content={block.content} />
                case "foundation_details": return <FoundationDetails key={index} content={block.content} />
                case "simple_statistics": return <SimpleStatistics key={index} content={block.content} />
                case "image_with_title_description": return <ImageWithTitleDescription key={index} content={block.content} />
                case "image_with_title": return <ImageWithTitle key={index} content={block.content} />
                case "tiles": return <Tiles key={index} content={block.content}/>
                case "slider": return <Slider key={index} content={block.content}/>
                case "avatars_list": return <AvatarsList key={index} content={block.content}/>
                case "avatars_list_with_description": return <AvatarsListWithDescription key={index} content={block.content}/>
                case "grey_bg_text": return <GreyBgText key={index} content={block.content}/>
                case "white_bg_text": return <WhiteBgText key={index} content={block.content}/>
                case "green_bg_text": return <GreenBgText key={index} content={block.content}/>
                case "download_files": return <DownloadFiles key={index} content={block.content}/>
                case "download_files_group": return <DownloadFilesGroup key={index} content={block.content}/>
                case "simple_text": return <DownloadFilesGroup key={index} content={block.content}/>
                case "collapsable_content": return <CollapsableContent key={index} content={block.content}/>
                case "news": return <News key={index}/>
                case "news_article": return <NewsArticle key={index} content={block.content}/>
                case "payment_form": return <PaymentSection key={index} content={block.content}/>
                case "cv_form": return <CvForm key={index} />
                case "calendar": return <Calendar key={index} content={block.content}/>
                case 'title_with_text': return <TitleWithText key={index} content={block.content}/>
                case 'fancy_circle': return <FancyCircle key={index} content={block.content}/>
                case 'image_slider': return <ImageSlider key={index} content={block.content}/>
                case 'download_guides': return <Guides key={index} content={block.content}/>
                case 'contact_details': return <ContactDetails key={index} content={block.content} />
                case 'contact_form': return <ContactForm key={index} />
                default: return <></>
            }
    })
}

export default getComponents;
