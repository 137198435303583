import {Grid, styled, Typography, useTheme} from "@mui/material";
import {WPImage} from "../model/WPImage";
import bubble from "../assets/images/bubble-right.png";
import SectionTitle from "../ui/SectionTitle";
import {useCustomTheme} from "../store/theme-context";

const Bubble = styled("img")({
    position: "absolute",
    right: 0,
    top: "-130px",
    zIndex: 1,
})

type Props = {
    content: any;
}

type TileProps = {
    tile: {
        tile_title: string;
        tile_image: WPImage;
    };
}

const Tile = ({tile}: TileProps) => {
    const {palette} = useTheme()
    const {isHighContrast} = useCustomTheme()
    return <Grid item container sx={{
        height: "645px",
        backgroundImage: `linear-gradient(0deg, rgba(66, 66, 66, 0.3), rgba(66, 66, 66, 0.3)), url(${tile.tile_image.sizes.large})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 2,
        "&:not(:last-child)": {
            mr: {xl: "10px"},
            mb: {xs: "10px", xl: "0"}
        },
        p: "0 10px",
    }} flexGrow={1}
        justifyContent={"center"}
         alignItems={"center"}
    >
        <Typography variant={"h5"} mt={"96px"} mb={"48px"} color={palette.custom.whiteText} sx={{backgroundColor: isHighContrast ? palette.custom.main : ''}} textAlign={"center"}>{tile.tile_title}</Typography>
    </Grid>
}

const Tiles = ({content}: Props) => {
    const {palette} = useTheme()
    return <Grid item container direction={"column"} alignItems={"center"} sx={{width: "100%", position: "relative"}}>
        <SectionTitle title={content.title} color={palette.custom.greenDarkTextBlack}/>
        <Bubble src={bubble} alt={"bubble"}/>
        <Grid item container wrap={"nowrap"} direction={{xs: "column",xl: "row"}}>
            {content.tiles_list && content.tiles_list.map((tile: any, index: number) => {return <Tile key={index} tile={tile}/>})}
        </Grid>
    </Grid>
}

export default Tiles;
