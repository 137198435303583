const LocalizationIcon = () => {
    return <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z" fill="#3D8D4F"/>
        <g clipPath="url(#clip0_59_3002)">
            <g clipPath="url(#clip1_59_3002)">
                <path d="M30.375 13.6289C37.3649 13.6289 43.0312 19.4161 43.0312 26.555C43.0312 28.9626 41.7951 31.865 39.5219 35.4833C38.9449 36.4018 38.3077 37.3553 37.5837 38.3912L37.1387 39.0231C36.357 40.125 33.7255 43.7052 33.2725 44.3453L32.9338 44.8316C32.1906 45.9158 31.7812 46.6328 31.7812 46.6622C31.7812 48.5112 29.1594 48.5749 28.9785 46.8535L28.9687 46.6622L28.9625 46.6455C28.9044 46.5195 28.4423 45.7288 27.6519 44.5937L27.3125 44.1152C26.5808 43.1013 24.325 40.0292 23.6113 39.0231C22.6997 37.7381 21.9205 36.5854 21.2281 35.4833C18.9549 31.865 17.7188 28.9626 17.7188 26.555C17.7188 19.4161 23.3851 13.6289 30.375 13.6289ZM30.375 16.5014C24.9384 16.5014 20.5312 21.0025 20.5312 26.555C20.5312 28.2499 21.5959 30.7498 23.5952 33.9321C24.1456 34.8083 24.7584 35.725 25.4582 36.7264L26.1401 37.689C27.1124 39.0401 29.3273 42.0557 29.7569 42.6626L30.2064 43.3057L30.3736 43.5514L30.9931 42.6626C31.4442 42.0253 33.8635 38.7325 34.746 37.4993L34.8613 37.3377C35.744 36.0933 36.4943 34.9835 37.1548 33.9321C39.1541 30.7498 40.2187 28.2499 40.2187 26.555C40.2187 21.0025 35.8116 16.5014 30.375 16.5014ZM30.375 20.8101C33.4816 20.8101 36 23.3822 36 26.555C36 29.7278 33.4816 32.2999 30.375 32.2999C27.2684 32.2999 24.75 29.7278 24.75 26.555C24.75 23.3822 27.2684 20.8101 30.375 20.8101ZM30.375 23.6825C28.8217 23.6825 27.5625 24.9686 27.5625 26.555C27.5625 28.1414 28.8217 29.4275 30.375 29.4275C31.9283 29.4275 33.1875 28.1414 33.1875 26.555C33.1875 24.9686 31.9283 23.6825 30.375 23.6825Z" fill="white"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_59_3002">
                <rect width="45.75" height="45.75" fill="white" transform="translate(7.5 7.50098)"/>
            </clipPath>
            <clipPath id="clip1_59_3002">
                <rect width="33.75" height="34.4696" fill="white" transform="translate(13.5 13.6289)"/>
            </clipPath>
        </defs>
    </svg>
}

export default LocalizationIcon;
