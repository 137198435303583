import {Box, Collapse, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {WPImage} from "../model/WPImage";
import large_bubble from "../assets/images/large-light-bubble.png";
import small_bubble from "../assets/images/small-light-bubble.png";
import {useState} from "react";
import DOMPurify from "dompurify";

type Props = {
    content: any;
}

type AvatarsRowProps = {
    avatars: any[];
    index: number;
}

type AvatarImageProps = {
    avatar: WPImage;
    isSelected?: boolean | undefined;
    onClick: () => void;
}

type AvatarWrapperProps = {
    data: {
        title: string;
        avatar: WPImage;
        subtitle: string;
        description: string;
    };
    onClick: () => void;
    isSelected?: boolean | undefined;
}

type BubbleProps = {
    xl?: boolean;
    top: any;
    left: any;
}

const Bubble = ({xl, top, left}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: left, zIndex: -10}}>
        <img src={xl ? large_bubble  : small_bubble} alt={"bubble_background"}/>
    </Box>
}

const AvatarImage = ({avatar, isSelected, onClick}: AvatarImageProps) => {
    const {palette} = useTheme();

    return <Grid item component={"img"} src={avatar.sizes.large}
         onClick={onClick}
         sx={{
        minWidth: "276px",
        minHeight: "276px",
        maxWidth: "276px",
        maxHeight: "276px",
        verticalAlign: "middle",
        borderRadius:  "50%",
        border: isSelected ? `4px solid ${palette.secondary.light}` : "none",
        cursor: "pointer",
    }}/>
}

const AvatarWrapper = ({data, onClick, isSelected}: AvatarWrapperProps) => {
    const {palette, breakpoints} = useTheme();
    const isSmall = useMediaQuery(breakpoints.down('md'));
    const [expand, setExpand] = useState(false);

    return <Grid item flexDirection={"column"} sx={{
        display: "flex",
        padding: {xs: "50px 0", xl: "0"},
        alignSelf: {xs: "center", xl: "start"},
        alignItems: "center",}}
        >
        <AvatarImage avatar={data.avatar} onClick={isSmall ? () => setExpand(!expand) : onClick} isSelected={isSelected}/>
        <Typography variant={"h5"} mt={"16px"} textAlign={"center"} color={palette.custom.greenDarkTextBlack}>{data.title}</Typography>
        <Typography variant={"body1"} mt={"4px"} textAlign={"center"} color={palette.secondary.light} sx={{textTransform: "uppercase", fontWeight: 700}}>{data.subtitle}</Typography>
        {isSmall && <Collapse in={expand} >
            <Typography sx={{color: palette.primary.main}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.description)}}/>
        </Collapse>}
    </Grid>
}

const AvatarsRow = ({avatars, index}: AvatarsRowProps) => {
    const {palette, breakpoints} = useTheme();
    const [expand, setExpand] = useState(index === 0);
    const [selectedIndex, setSelectedIndex] = useState<number>(index === 0 ? 0 : -1);
    const isSmall = useMediaQuery(breakpoints.up('md'));

    const handleClick = (index: number) => {
        if(!expand) {
            setSelectedIndex(index);
            setExpand(!expand);
        } else if(expand && index !== selectedIndex) {
            setSelectedIndex(index);
        } else {
            setTimeout(() => setSelectedIndex(-1), 500)
            setExpand(!expand);
        }
    }

    return <><Grid key={index} item container justifyContent={"space-between"} flexDirection={{xs: "column", md: "row"}} wrap={"nowrap"} sx={{width: {lg: "1175px", xs: "90%"}}}>
        {0 < avatars.length && <AvatarWrapper data={avatars[0]} onClick={() => handleClick(0)} isSelected={selectedIndex === 0}/>}
        {1 < avatars.length && <AvatarWrapper data={avatars[1]} onClick={() => handleClick(1)} isSelected={selectedIndex === 1}/>}
        {2 < avatars.length && <AvatarWrapper data={avatars[2]} onClick={() => handleClick(2)} isSelected={selectedIndex === 2}/>}
    </Grid>
        {isSmall && <Grid key={"description" + index} item container wrap={"nowrap"} sx={{mb: "80px", mt: "24px", width: {lg: "1175px", xs: "90%"}}} >
        <Collapse in={expand} >
            <Typography sx={{color: palette.primary.main}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(avatars[selectedIndex] && avatars[selectedIndex].description)}}/>
        </Collapse>
    </Grid>}
    </>
}

const AvatarsListWithDescription = ({content}: Props) => {
    const renderAvatars = (avatars: any[]) => {
        const elements = []
        if(avatars && avatars.length > 0){
            for(let i = 0; i < avatars.length; i+= 3) {
                elements.push(<AvatarsRow avatars={avatars.slice(i, i + 3)} index={i}/>)
            }
        }
        return elements;
    }


    return <Grid item container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", position: "relative", overflow: "hidden"}} pt={"146px"} pb={"92px"}>
        {renderAvatars(content.avatars)}
        <Bubble top={"-50px"} left={"-70px"}/>
        <Bubble top={"55%"} left={"55%"}/>
        <Bubble top={"75%"} left={"-70px"}/>
    </Grid>
}

export default AvatarsListWithDescription;
