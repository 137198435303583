import {Grid, Typography, Box, IconButton} from "@mui/material";
import DOMPurify from "dompurify";
import Ellipse from '../assets/images/ellipse.svg';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useState} from "react";

type Props = {
    content: any;
}

type SliderButtonProps = {
    onClick: () => void;
    fwd: boolean;
}

const SliderButtonDark = ({onClick, fwd}: SliderButtonProps) => {

    return <IconButton
        onClick={onClick}
        sx={{
            backgroundColor: 'secondary.main',
            "&:hover": {
                backgroundColor: 'secondary.main',
            },
            color: 'white',
            padding: {xl: "25px", md: "20px", xs: "15px"},
        }}>
        {fwd ?
            <ArrowForwardIosIcon/>
            :
            <ArrowBackIosIcon/>
        }
    </IconButton>
}

const FancyCircle = ({content}: Props) => {

    const [index, setIndex] = useState(0)

    const next = () => {
        setIndex(index === content.content.length - 1 ? 0 : index + 1)
    }

    const prev = () => {
        setIndex(index === 0 ? content.content.length - 1 : index - 1);
    }

    return (
        <Grid container justifyContent="center" alignItems="center" pt={12} pb={22} sx={{position: 'relative', backgroundColor: `${content.bg ? '#F4F4F4' : '#fff'}`}}>
            <Grid container item justifyContent="center" alignItems="center" xs={12} lg={9} xl={8}>
                <Grid item pb={{xs: 6, lg: 22}} xs={11} sx={{textAlign: 'center'}}>
                    <Typography variant="h5" color="secondary.main">{content.title}</Typography>
                </Grid>
                <Grid container item flexDirection={{xs: 'column', lg: 'row'}} justifyContent={{xs: 'center', lg:"space-between"}} alignItems="center">
                    <Box sx={{maxWidth: {xs: '90%', lg: '50%'}}} pb={{xs: 12, sm: 16, lg: 0}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.content[index].description)}} />
                    <Grid item justifyContent={{xs: 'center', lg:"space-between"}} sx={{position: 'relative', display: 'flex', width: {xs: '308px', sm: '340px'}}} mr={{xs: 0, xl: 6}}>
                        <Box component='img'
                             sx={{
                                 borderRadius: '100%',
                                 width: {xs: '60%', sm: '80%', xl: '100%'}
                             }}
                             alt={content.content[index].img.alt}
                             src={content.content[index].img.url}
                        />
                        <Box component='img'
                             sx={{
                                 position: 'absolute',
                                 top: {xs: '-25%', sm: '-19%', xl: '-20%'},
                                 left: {xs: '5%', sm: '-5%', lg: '-15%', xl: '-20%'},
                                 transform: `rotate(${index * 90}deg)`,
                                 transition: 'all 1s',
                                 width: {xs: '90%', sm: '110%', xl: '140%'}
                            }}
                             alt='Fundacja Pomocy Dzieciom'
                             src={Ellipse}
                        />
                    </Grid>
                </Grid>
                <Grid container item justifyContent={{xs: 'center', lg: "flex-end"}} alignItems="center" pt={14}>
                    <Grid item sx={{width: {xs: '308px', sm: '377px', xl: '480px'}, display: 'flex', alignItems: "center", justifyContent: 'space-between'}} mr={{xs: 0, lg: 2, xl: -3}}>
                        <SliderButtonDark fwd={false} onClick={prev}/>
                        <Typography variant="h5" color="secondary.main">{content.content[index].name}</Typography>
                        <SliderButtonDark fwd={true} onClick={next}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FancyCircle;
