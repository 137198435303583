import {createContext, ReactNode, useReducer} from "react";

type PaymentType = {type: "regular" | "single", amount: number};
type PersonalDetailsType = {firstName: string, lastName: string, email: string, street?: string, city?: string, zipCode?: string}

type PaymentContextType = {
    tabIndex: number;
    firstName: string;
    lastName: string;
    email: string;
    street: string | undefined;
    city: string | undefined;
    zipCode: string | undefined;
    amount: number;
    type: "regular" | "single";
    setIndex: (index: number) => void,
    setPayment: (payment: PaymentType) => void;
    setPersonalDetails: (personalDetails: PersonalDetailsType) => void;
};

type ActionType = {
    type: string;
    value?: any;
};

const SET_PAYMENT = "setPayment";
const SET_PERSONAL_DETAILS = "setPersonalDetails";
const SET_INDEX = "setIndex";

const INITIAL_PAYMENT_STATE: PaymentContextType = {
    tabIndex: 0,
    firstName: "",
    lastName: "",
    email: "",
    street: undefined,
    city: undefined,
    zipCode: undefined,
    amount: 0,
    type: "single",
    setIndex: (index: number) => {},
    setPayment: (payment: PaymentType) => {},
    setPersonalDetails: (personalDetails: PersonalDetailsType) => {},
}
const paymentReducer = (state: PaymentContextType, action: ActionType): PaymentContextType => {
    switch (action.type) {
        case SET_PERSONAL_DETAILS: {
            return {
                ...state,
                firstName: action.value.firstName,
                lastName: action.value.lastName,
                email: action.value.email,
                street: action.value.street,
                city: action.value.city,
                zipCode: action.value.zipCode,
            };
        }
        case SET_PAYMENT: {
            return {
                ...state,
                amount: action.value.amount,
                type: action.value.type,
            };
        }
        case SET_INDEX: {
            return {
                ...state,
                tabIndex: isNaN(action.value) ? 0 : action.value,
            }
        }
        default:
            return state;
    }
};

export const PaymentContextProvider = ({children} : {children: ReactNode} ) => {
    const [paymentState, dispatch] = useReducer(paymentReducer, INITIAL_PAYMENT_STATE);

    const setPayment = (paymentDetails: PaymentType) => {dispatch({type: SET_PAYMENT, value: paymentDetails})};
    const setPersonalDetails = (paymentDetails: PersonalDetailsType) => {dispatch({type: SET_PERSONAL_DETAILS, value: paymentDetails});};
    const setIndex = (index: number) => {dispatch({type: SET_INDEX, value: index});};

    const contextValue: PaymentContextType = {
        ...paymentState,
        setIndex: setIndex,
        setPayment: setPayment,
        setPersonalDetails: setPersonalDetails,
    };
    return <PaymentContext.Provider value={contextValue}>{children}</PaymentContext.Provider>;
};

const PaymentContext = createContext<PaymentContextType>({
    ...INITIAL_PAYMENT_STATE,
});

export default PaymentContext;
