import Logo from "../../ui/Logo";
import {AppBar, Box, Toolbar, Typography, useTheme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import ContentContext from "../../store/content-context";
import {NavLink, useLocation} from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {MenuItem} from "../../model/Menu";
import {PayOnlineButton} from "./Header";
import SearchPageField from "./SearchPageField";
import WCAGMenu from "./WCAGMenu";

type Props = {
    item: MenuItem;
    index: number;
    activeParentIndex: number;
    color?: string;
}
const MenuItemLink = ({item, index, activeParentIndex, color} : Props) => {
    const {palette} = useTheme();

    return <NavLink to={item.subMenu && item.subMenu[0] && item.subMenu[0].url ? `${item.subMenu[0].url}` : `${item.url}`} style={{textDecoration: "none"}} key={index}>
        {
            ({isActive}) => {
                return (
                    <Box sx={{display: "flex", alignItems: "center", margin: {xs: '16px 24px', lg: '16px 8px', xl: "16px 24px"}}}>
                        <Typography variant={"body2"} sx={{
                            textTransform: "none",
                            color: color ? color : palette.primary.main,
                            whiteSpace: "nowrap",
                            fontWeight: isActive || index == activeParentIndex ? 700 : 400
                        }}>
                            {item.title}
                        </Typography>
                        {item.subMenu && item.subMenu[0] && <ArrowDropDownIcon fontSize="small" sx={{color: palette.primary.main}}/>}
                    </Box>)
            }}</NavLink>
}

const FullHeader = () => {
    const {menu} = useContext(ContentContext);
    const [subMenu, setSubMenu] = useState<MenuItem[] | undefined>([]);
    const [activeParentIndex, setActiveParentIndex] = useState(-1);
    const {palette} = useTheme();
    const location = useLocation();
    const isSpecialPage = location.pathname === '/co-robimy/projekty/zaopiekowani';

    useEffect(() => {
        const parentPath = location.pathname.split("/")[1];
        const parentItem = menu.menuItems.find(item => item.url == ("/" + parentPath));
        if(parentItem) {
            setSubMenu(parentItem.subMenu);
            setActiveParentIndex(menu.menuItems.indexOf(parentItem));
        } else {
            setSubMenu([]);
            setActiveParentIndex(-1);
        }
    }, [location]);

    const renderSubMenu = () => {
        return subMenu && subMenu.length > 0 &&
            (
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {!isSpecialPage && <Box sx={{
                        width: "100%",
                        height: "83px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 99,
                        position: "relative",
                        backgroundColor: "white",
                    }}>
                        {subMenu.map((item, index) => {
                            return <NavLink to={`${item.url}`} style={{textDecoration: "none", margin:"0 24px"}} key={index} >
                                {({isActive}) => {
                                    return <Typography variant={"body2"}
                                                       sx={{textTransform: "none", color: palette.primary.main, fontWeight: isActive ? 700 : 400}}>
                                        {item.title}
                                    </Typography>
                                }}
                            </NavLink>
                        })}
                    </Box>}
                    {isSpecialPage && <Box sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                        marginTop: '25px'
                    }}>
                        <SearchPageField />
                    </Box>}
                </Box>
            )
    }

    return <><AppBar position="relative" sx={{backgroundColor: "white", zIndex: 100}}
                     component={"header"}>
        <Toolbar sx={{ margin: "0 5%", padding: "14px 0"}}>
            <Box sx={{flex: 1, display: "flex", maxHeight: "100%"}}>
                <Logo/>
            </Box>
            {!isSpecialPage && <Box sx={{display: "flex"}}>
                {menu.menuItems.map((item, index) => {
                    return <MenuItemLink key={index} item={item} index={index} activeParentIndex={activeParentIndex}/>;
                })}
                <MenuItemLink item={{title: "Zaloguj się", url: "login", subMenu: []}} index={-2} activeParentIndex={activeParentIndex} color={palette.custom.greenDarkTextBlack}/>
            </Box>}
            <WCAGMenu />
            <Box sx={{display: "flex", justifyContent: "end"}}>
                <PayOnlineButton/>
            </Box>
        </Toolbar>
    </AppBar>
        {renderSubMenu()}
    </>
}

export default FullHeader;
