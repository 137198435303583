import React, {useContext, useEffect} from 'react';
import './App.css';
import {getAllPages, getAllPosts, getAllPostsByPage, getMenu, getSettings} from "./api/content-api";
import ContentContext from "./store/content-context";
import {Navigate, Route, Routes} from 'react-router-dom';
import ErrorPage from "./pages/ErrorPage";
import Page from "./model/Page";
import ScrollToTop from "./pages/ScrollToTop";
import TransferForm from "./components/PaymentForm/TransferForm";
import LoginPage from "./pages/LoginPage";
import AuthContext from "./store/auth-context";
import AccountPage from "./pages/AccountPage";
import AccountsListPage from "./pages/AccountsListPage/AccountsListPage";
import CustomHead from "./layout/CustomHead";

function App() {
    const {pages, news, setPages, setPosts, setMenu, setSettings} = useContext(ContentContext);
    const {token, isAdmin} = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            const pages = await getAllPages();
            const posts = await getAllPostsByPage(setPosts, 1);
            const menu = await getMenu();
            const settings = await getSettings();
            const newsPages = posts.filter(post => post.type === "news").map(post => post.toPage());
            setPages([...pages, ...newsPages]);
            setPosts(posts);
            setMenu(menu);
            setSettings(settings);
        }
        fetchData().catch(error => console.log(error));
    }, [])

    return (
        <ScrollToTop>
            <CustomHead />
            <Routes>
                {pages.map((page: Page) => page.toRoute())}
                <Route key={"transfer-form"} path={"/transfer-form"} element={<TransferForm/>}/>
                <Route key={"login-page"} path={"/login"} element={<LoginPage />}/>
                <Route key={"account-details"} path={"/account"} element={token ? <AccountPage/> : <Navigate replace to={"/login"}/>}/>
                <Route key={"accounts"} path={"/accounts"} element={token && isAdmin ? <AccountsListPage/> : <Navigate replace to={"/login"}/>}/>
                <Route key={"default"} path={"*"} element={pages.length > 0 ? <ErrorPage/> : <></>}/>
            </Routes>
        </ScrollToTop>
    );
}

export default App;
