import {
    Grid,
    Button,
    TextField,
    Typography,
    useTheme,
    styled,
    Alert,
    debounce
} from "@mui/material";
import {useState, FC} from "react";
import {useDebounce} from "use-debounce";
import {sendMail} from "../api/contact-api";
import validator from 'validator';

const TextFiledStyled = styled(TextField)(({theme}) => ({
    borderRadius: '5px',
    border: '2px #3D8D4F solid',

    '& fieldset': {
        border: 'none'
    },

    '& label': {
        fontSize: '18px',
        fontWeight: 700,
        color: theme.palette.primary.light,
        transform: 'none',
        top: '-30px',
        left: '30px'
    }
}))

const ContactForm: FC = () => {
    const {palette} = useTheme();

    const [message, setMessage] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    })

    const [formValidation, setFormValidation] = useState({
        nameError: '',
        lastNameError: '',
        emailError: '',
        messageError: '',
    })

    const [alert, setAlert] = useState(false);
    const [alertInfo] = useDebounce(alert, 500);
    const [alertText, setAlertText] = useState('');

    const nameChangeHandler = (event: { target: { value: any; }; }) => {
        const value = event.target.value.trim();
        if (value.length < 2) {
            setFormValidation({
                ...formValidation,
                nameError: 'Wprowadź imię'
            })
        } else {
            setFormValidation({
                ...formValidation,
                nameError: ''
            })
        }
        setMessage((prevState) => {
            return {
                ...prevState,
                firstName: value
            }
        })
    }

    const lastnameChangeHandler = (event: { target: { value: any; }; }) => {
        const value = event.target.value.trim();
        if (value.length < 2) {
            setFormValidation({
                ...formValidation,
                lastNameError: 'Wprowadź nazwisko'
            })
        } else {
            setFormValidation({
                ...formValidation,
                lastNameError: ''
            })
        }
        setMessage((prevState) => {
            return {
                ...prevState,
                lastName: value
            }
        })
    }

    const emailChangeHandler = (event: { target: { value: any; }; }) => {
        const value = event.target.value.trim();
        if (value.length < 2) {
            setFormValidation({
                ...formValidation,
                emailError: 'Wprowadź poprawny email'
            })
        } else {
            setFormValidation({
                ...formValidation,
                emailError: ''
            })
        }
        setMessage((prevState) => {
            return {
                ...prevState,
                email: value
            }
        })
    }

    const messageChangeHandler = (event: { target: { value: any; }; }) => {
        const value = event.target.value;
        if (value.length < 2) {
            setFormValidation({
                ...formValidation,
                messageError: 'Puste pole wiadomości'
            })
        } else {
            setFormValidation({
                ...formValidation,
                messageError: ''
            })
        }
        setMessage((prevState) => {
            return {
                ...prevState,
                message: value
            }
        })
    }

    const resetInputs = () => {
        setMessage((prevState) => {
            return {
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            }
        })
    }

    const validateForm = () => {
        let isValid = true;
        for (const error in formValidation) {
            // @ts-ignore
            const fieldValue = formValidation[error];
            if (fieldValue !== "") {
                isValid = false;
            }
        }
        for (const field in message) {
            // @ts-ignore
            const fieldValue = message[field];
            if (fieldValue === "") {
                isValid = false;
            }
        }
        return isValid;
    }

    const debouncedAlert = debounce(() => setAlert(false), 5000);

    const actionSend = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (!validateForm()) {
            setAlertText('Wypełnij wszystkie pola formularza.');
            setAlert(true);
            debouncedAlert();
            return
        }
        if(!validator.isEmail(message.email)) {
            setAlertText('Wprowadź poprawny email.');
            setAlert(true);
            debouncedAlert();
            return
        }
        if (message) {
            sendMail(message, (error) => error).then((res: any) => {
                setAlertText('Dziękujemy za wysłanie wiadomości.');
                setAlert(true);
                debouncedAlert();
                resetInputs();
            }).catch((error: any) => {
                setAlertText('Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.');
                setAlert(true);
                debouncedAlert();
            })
        }
    }

        return (
            <Grid container item flexDirection={'column'} justifyContent={'center'} alignItems={"center"} pt={12}
                  pb={12} sx={{backgroundColor: palette.secondary.light}}>
                <Typography variant="h5" color={palette.custom.greenDarkText} pb={7} sx={{textAlign: 'center'}}>Skontaktuj się z nami przez
                    formularz</Typography>
                <form>
                    <Grid container xs={12} item justifyContent={"center"} alignItems={"center"} flexDirection={'column'}
                          sx={{backgroundColor: 'white', width: {xs: '100vw', md: '870px'}}}>
                        <Grid item sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', justifyContent: {xs: 'center', md: 'space-between'}, width: {xs: '90%', md: '80%'}}} pt={8}>
                            <TextFiledStyled
                                sx={{width: {xs: '100%', md: '45%'}}}
                                onChange={nameChangeHandler}
                                value={message.firstName}
                                helperText={formValidation.nameError}
                                error={formValidation.nameError !== ''}
                                required
                                label="Imię"/>
                            <TextFiledStyled
                                sx={{width: {xs: '100%', md: '45%'}, marginTop: {xs: '56px', md: 0}}}
                                onChange={lastnameChangeHandler}
                                value={message.lastName}
                                helperText={formValidation.lastNameError}
                                error={formValidation.lastNameError !== ''}
                                required
                                label="Nazwisko"/>
                        </Grid>
                        <Grid item sx={{width: {xs: '90%', md: '80%'}}} pt={7}>
                            <TextFiledStyled
                                fullWidth
                                onChange={emailChangeHandler}
                                value={message.email}
                                helperText={formValidation.emailError}
                                error={formValidation.emailError !== ''}
                                required
                                type='email'
                                label="E-mail"/>
                        </Grid>
                        <Grid item sx={{width: {xs: '90%', md: '80%'}}} pt={7}>
                            <TextFiledStyled
                                fullWidth
                                onChange={messageChangeHandler}
                                value={message.message}
                                helperText={formValidation.messageError}
                                error={formValidation.messageError !== ''}
                                multiline
                                rows={8}
                                required
                                label="Twoja wiadomość"/>
                        </Grid>
                        {alertInfo ?
                            <Alert severity={alertText === 'Dziękujemy za wysłanie wiadomości.' ? "success" : "error"} sx={{marginTop: '20px'}}>
                                {alertText}
                            </Alert> : ''
                        }
                        <Grid item pt={8} pb={6}>
                            <Button type="submit" variant="contained" sx={{
                                backgroundColor: palette.custom.greenDarkTextBlack,
                                color: 'white',
                                padding: '15px 45px',
                                borderRadius: '32px',
                                fontSize: '18px',
                                textTransform: 'capitalize'
                            }} onClick={actionSend}>Wyślij</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );
}

export default ContactForm;
