import {getRelativeLink} from "../utils/string-tools";

export class MenuItem {
    title: string;
    url: string;
    subMenu: MenuItem[] | undefined;

    constructor(title: string, url: string, subMenu?: MenuItem[]) {
        this.title = title;
        this.url = url;
        this.subMenu = subMenu;
    }
}

class Menu{
    menuItems: MenuItem[];

    constructor(menuItems: MenuItem[]) {
        this.menuItems = menuItems;
    }

    public static fromApiResponse(data: any) {
        const mainMenu = data.filter((item: any) => item.menu_item_parent == 0).sort( (a: any, b: any) => a.menu_order - b.menu_order);
        const menu = mainMenu.map((mainItem: any) => {
            const subMenus = data.filter((item: any) => item.menu_item_parent == mainItem.ID)
                .sort( (a: any, b: any) => a.menu_order - b.menu_order)
                .map( (item: any) => new MenuItem(item.title, getRelativeLink(item.url)));
            return new MenuItem(mainItem.title, getRelativeLink(mainItem.url), subMenus);
        })
        return new Menu(menu);
    }

}

export default Menu;