import {Box, Grid, useTheme} from "@mui/material";
import large_bubble from "../assets/images/large-light-bubble.png";
import DOMPurify from 'dompurify';

type Props = {
    content: any;
}

type BubbleProps = {
    top: any;
    horizontal: any;
}

const Bubble = ({top, horizontal}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: horizontal.left, right: horizontal.right}}>
        <img src={large_bubble} alt={"bubble_background"}/>
    </Box>
}

const WhiteBgText = ({content}: Props) => {
    const {palette} = useTheme();

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", position: "relative", overflow: "hidden"}}>
        <Grid item sx={{width: {lg: "1175px", xs: "90%"}, p: "96px 0", m: "0 auto", color: palette.primary.main, zIndex: 1}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.text)}}/>
        <Bubble top={"-120px"} horizontal={{right: "-150px"}}/>
    </Grid>
}

export default WhiteBgText;