import Block from "./Block";
import {getRelativeLink} from "../utils/string-tools";
import getComponents from "../pages/getComponents";
import {Route} from "react-router-dom";
import MainLayout from "../layout/MainLayout";

class Page {
    link: string;
    title: string;
    blocks: Block[];

    constructor(link: string, title: string , blocks: Block[]) {
        this.link = link;
        this.title = title;
        this.blocks = blocks;
    }

    public static fromApiResponse(data: any) {
        const pages = [] as Page[];
        data.forEach((d: any) => {
            if(d.acf.blocks) {
                const blocks = d.acf.blocks.map((block: any) => new Block(block.acf_fc_layout, block));
                pages.push(new Page(getRelativeLink(d.link), d.title.rendered, blocks))
            }
        })
        return pages;
    }

    public toRoute() {
        const components = getComponents(this.blocks);
        return <Route key={this.title} path={this.link} element={<MainLayout>{components}</MainLayout>}/>
    }

}

export default Page;