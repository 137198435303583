import {Typography, useTheme} from "@mui/material";

type Props = {
    title: string;
    color?: string;
    margin?: boolean;
}

const SectionTitle = ({title, color, margin}: Props) => {
    const {palette} = useTheme();

    return <Typography variant={"h5"} mt={`${!margin ? "96px" : '0px'}`} mb={`${!margin ? "48px" : '0px'}`} color={color ? color : palette.custom.greenDarkText} textAlign={"center"} sx={{width: '100%', zIndex: 10, fontWeight: 700}}>
        {title}
    </Typography>

}

export default SectionTitle;
