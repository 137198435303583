import {Button, Grid, styled, Typography, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {WPImage} from "../../model/WPImage";

const WhiteButton = styled(Button)(({theme}) => ({
    padding: "13px 48px",
    marginTop: "38px",
    borderRadius: "100px",
    border: "none",
    fontWeight: 700,
    fontSize: "17px",
    textTransform: "none",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.custom.whiteText,

    color: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.grey[100],
    }
}))

type Props = {
    content: string;
    image: WPImage;
    link: any;
}

const PaymentBanner = ({content, image, link}: Props) => {
    const {palette} = useTheme();
    const navigate = useNavigate();

    return <Grid container alignItems={"center"} flexDirection={{xs: "column", md: "row"}} flexWrap="nowrap" sx={{width: "100%", height: {xs:"auto", md: "450px"}, m: "96px auto", backgroundColor: palette.secondary.light, position: "relative"}}>
        <Grid item sx={{
            height: {xs:"450px", md: "100%"},
            minWidth: {xs: "100%", md: "475px"},
            maxWidth: {xs: "100%", md: "475px"},
            backgroundImage: `url(${image.sizes.medium})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
        </Grid>
        <Grid item container flexDirection={"column"} alignItems={"center"} mb={{xs: "60px", md: 0}}>
            <Typography variant={"h5"} color={"white"} textAlign={"center"} sx={{width: "80%", mt:"60px"}}>{content}</Typography>
            <WhiteButton onClick={() => navigate(link.url)}>Zobacz</WhiteButton>
        </Grid>
    </Grid>
}

export default PaymentBanner;
