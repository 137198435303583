import {Box, Grid, Button, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import {useCustomTheme} from "../store/theme-context";

type Props = {
    content: any;
}

const ImageWithText = ({content}: Props) => {
    const {palette} = useTheme();
    const { isHighContrast } = useCustomTheme();

    return (
            <Grid container direction="row" alignItems="center"
                  sx={{
                      zIndex: 1,
                      position: 'relative',
                      justifyContent: {xs: 'center', lg: 'flex-start'}
                  }}>
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        objectFit: 'cover',
                        minHeight: '562px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        filter: isHighContrast ? 'contrast(0.3)' : '',
                    }}
                    src={content.image.url}
                    alt={content.image.alt}
                />
                <Grid container item  sx={{
                    zIndex: 2,
                    ml: {xs: 0, lg: '20%'}
                }} xs={10} md={8} lg={6} xl={4}>
                    <Grid item pt={'104px'}>
                        <Typography variant={"h4"} color={palette.custom.white}>{content.title}</Typography>
                    </Grid>
                    <Grid item pt={'48px'}>
                        <Typography variant={"body1"} color={palette.custom.white}>{content.description}</Typography>
                    </Grid>
                    {content.button &&
                    <Grid item pt={'86px'}>
                        <Button href={content.button[0].link.url} variant="contained" sx={{
                            backgroundColor: palette.custom.greenDarkTextBlack,
                            padding: '20px 48px',
                            borderRadius: '32px',
                            color: palette.custom.whiteText,
                            textTransform: 'none',
                            boxShadow: 'none',
                        }}>{content.button[0].button}</Button>
                    </Grid>}
                </Grid>
            </Grid>
    )
}

export default ImageWithText;
