import {useContext, useEffect, useState} from "react";
import ContentContext from "../store/content-context";
import {Box, Button, Grid, InputAdornment, styled, TextField, Typography, useTheme} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import SectionTitle from "../ui/SectionTitle";
import Post from "../model/Post";
import {useNavigate} from "react-router-dom";
import {useDebounce} from "use-debounce";
import {useCustomTheme} from "../store/theme-context";

export const SubjectButton = styled(Button)(({theme}) => ({
    padding: "0 27px",
    margin: "12px 12px 0 12px",
    borderRadius: "100px",
    border: "none",
    textTransform: "none",
    whiteSpace: "nowrap",

    '&:hover': {
        backgroundColor: theme.palette.custom.greenDarkTextBlack,
        color: 'white'
    }
}))

const MoreButton = styled(Button)({
    height: "50px",
    padding: "20px 48px",
    borderRadius: "100px",
    border: "2px solid",
    fontWeight: 700,
    textTransform: "none",
    whiteSpace: "nowrap",
})

const SearchField = styled(TextField)(({theme}) => ({
    borderRadius: "25px",
    border: "none",
    backgroundColor: theme.palette.grey[200],
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "none",
        },
    }
}))

type NewsTileProps = {
    news: Post,
    large?: boolean,
}

const NewsTile = ({news, large}: NewsTileProps) => {
    const { isHighContrast } = useCustomTheme();
    const {palette} = useTheme();
    const navigate = useNavigate();

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: {xs:"100%", lg: large ? "66%" : "32%"},
        height: {xs: "425px", lg: large ? "425px" : "287px"},
        backgroundImage: `url(${news.image.sizes.large})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        cursor: "pointer",
        mt:"2%",

    }}
    onClick={() => {navigate(news.path)}}
    >
        <Grid item>
            <SubjectButton disabled sx={{backgroundColor: palette.custom.greenDarkTextBlack, color: "white !important"}}>{news.subject}</SubjectButton>
        </Grid>
        <Grid item container flexDirection={"column"} justifyContent={"Center"} sx={{backgroundColor: isHighContrast ? palette.custom.main : `${palette.grey[700]}99`, width: {xs: '80%', sm: "70%"}, padding: '10px', pl: "12px", mb: "5%"}}>
            <Typography color={"white"} variant={large ? "h6" : "body2"} sx={{fontWeight: 400}}>{news.publishedDate.toLocaleDateString("pl")}</Typography>
            <Typography color={"white"} variant={large ? "h5" : "h6"}  sx={{lineHeight: 1, mt: "10px"}}>{news.title}</Typography>
        </Grid>
    </Box>
}

const News = () => {
    const NEWS_PER_PAGE = 5
    const [searchInput, setSearchInput] = useState("");
    const [searchValue] = useDebounce(searchInput, 1000);
    const [selectedSubject, setSelectedSubject] = useState(-1);
    const [pagingIndex, setPagingIndex] = useState(0);
    const {palette} = useTheme();
    const {news, newsSubjects} = useContext(ContentContext);
    const [displayedNews, setDisplayedNews] = useState(news.slice(0, NEWS_PER_PAGE));

    useEffect(() => {
        let filteredNews;
        if(searchValue != "") {
            filteredNews = news.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase()) || item.description.toLowerCase().includes(searchValue.toLowerCase()))
        } else {
            filteredNews = news;
        }
        if(selectedSubject >= 0) {
            filteredNews = filteredNews.filter(item => item.subject === newsSubjects[selectedSubject]);
        }
        setDisplayedNews(filteredNews.slice(0, pagingIndex + NEWS_PER_PAGE));
    }, [selectedSubject, pagingIndex, searchValue]);

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: {lg: "1175px", xs: "90%"}, m: "0 auto", color: "transparent", position: "relative"}}>
        <Grid item container alignItems={"center"} mt={"96px"} sx={{flexWrap: {xs:'wrap', md:"nowrap"}}}>
                <Grid item container alignItems={{xs:"start", lg: "center"}}  flexDirection={{xs: "column", lg: "row"}}>
                    <Grid item>
                        <Typography variant={"h6"} color={palette.custom.main} sx={{fontSize: "24px", mr: "17px"}}>Tematy:</Typography>
                    </Grid>
                    <Grid item sx={{display: 'flex', flexWrap: 'wrap', rowGap: '6px'}}>
                        {newsSubjects.map((subject: string, index: number) =>
                            <SubjectButton color={"secondary"} key={index} onClick={() => setSelectedSubject(selectedSubject === index ? -1 : index)}
                                           sx={{backgroundColor: selectedSubject === index ? palette.custom.greenDarkTextBlack : "white",
                                               color: selectedSubject === index ? "white" : palette.custom.greenDarkTextBlack,
                                               border: selectedSubject === index ? "none" : "1px solid",
                                               mt: {xs: "12px", lg: 0}
                                           }}>
                                {subject}
                            </SubjectButton>)}
                    </Grid>
                </Grid>
                <Grid item container alignItems={"start"} justifyContent={{xs:'center',md:"end"}} mt={{xs: 3, md: 0}}>
                    <SearchField placeholder={"Szukaj"} size={"small"} InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
                    }} onChange={(event) => setSearchInput(event.target.value)}/>
                </Grid>
            </Grid>
            {selectedSubject < 0 && <SectionTitle title={"Wszystkie aktualności"} color={palette.custom.greenDarkTextBlack}/>}
        <Box mb={`${displayedNews.length !== news.length ? '24px' : '96px'}`} sx={{display: "flex", flexWrap: "wrap", width: "100%", flexDirection: {xs: "column", lg: "row"}, gap: '23px', justifyContent: "flex-start", alignItems: {xs: "center", lg: "end"}, mt: selectedSubject < 0 ? 0 : "96px"}}>
            {displayedNews.map((item: Post, index: number) => <NewsTile news={item} key={index} large={index === 0 && selectedSubject < 0}/>)}
        </Box>
        {
            displayedNews.length > 1 && displayedNews.length !== news.length ?
                <MoreButton sx={{mt: "72px", mb: "96px"}} color={"secondary"} onClick={() => {setPagingIndex(pagingIndex + NEWS_PER_PAGE)}}>Zobacz więcej</MoreButton>
            : ''
        }
    </Grid>
}

export default News;
