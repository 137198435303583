import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import Form from './Form';

type Props = {
    content: any;
}

const HeaderWithForm = ({content}: Props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container sx={{
            zIndex: 1,
            position: 'relative',
            padding: {xs: '15dvw 20px', md: '13dvw 0'}
        }}>
            {matches ?
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        inset: 0,
                        height: '100%'
                        // objectPosition: {xs: '55%', sm: 'auto'},
                        // minHeight: '689px'
                    }}
                    src={content.graphics.graphics_desktop.url}
                    alt={content.graphics.graphics_desktop.alt}
                />
            :
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        inset: 0,
                        height: '100%'
                    }}
                    src={content.graphics.graphics_mobile ? content.graphics.graphics_mobile.url : content.graphics.graphics_desktop.url}
                    alt={content.graphics.graphics_mobile ? content.graphics.graphics_mobile.alt : content.graphics.graphics_desktop.alt}
                />
            }
            <Grid container item alignItems="center" sx={{
                zIndex: 2,
                justifyContent: {xs: 'center',md: 'space-around'}
            }}>
                <Grid item xs={12} sm={8} md={6} lg={6} xl={4} ml={{md: 0, lg:6, xl:14}} mb={{xs: 0, md: 20}}>
                    {content.title ? <Typography variant={"h2"} sx={{lineHeight: '57.75px'}} color={theme.palette.custom.whiteText}>{content.title}</Typography> : ''}
                </Grid>
                <Grid item xs={12} sm={8} md={5} lg={4} xl={3} pt={{xs: 2, sm: 3, md: 0}} pb={{xs: 5, sm: 3, md: 0}}>
                    <Form/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HeaderWithForm;
