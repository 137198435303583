import {Box, Grid, Typography, useTheme} from "@mui/material";
import {useState} from "react";

type Props = {
    content: any;
}

const ImageWithTitle = ({content}: Props) => {
    const {palette} = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Grid container item direction="column" justifyContent="center" alignItems="center" xs={12} pt={12} pb={12}>
            <Typography variant='h5' color={palette.custom.greenDarkTextBlack} sx={{textAlign: {xs: 'center', lg: 'auto'}}}>{content.title}</Typography>
            <Box sx={{display: 'flex', position: 'relative'}}>
                <Box component='img' sx={{
                    objectFit: 'cover',
                    width: {xs:  '100%', xl: '1152px'},
                    maxWidth: '1152px'
                }}
                     onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}
                     alt={content.img[0].img.alt}
                     src={content.img[0].img.url}
                />
                {content.img[0].img.caption && <Box sx={{
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: '#f5f5f5',
                    padding: '6px 12px',
                    borderRadius: '12px',
                    maxWidth: '300px',
                    fontSize: '14px',
                    zIndex: 2,
                    lineHeight: 'normal',
                    display: isHovered ? 'flex' : 'none'
                }}>
                    {content.img[0].img.caption}
                </Box>}
            </Box>
        </Grid>
    )
}

export default ImageWithTitle;
