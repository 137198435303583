const Heart = () => {
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M28.1625 3.33301C30.8924 3.33301 33.4617 4.48812 35.3887 6.46832C37.4762 8.44852 38.6003 11.2538 38.2791 14.0591C38.2791 16.8644 37.1551 19.6696 35.2281 21.6498L21.097 36.1713C20.7758 36.5013 20.4547 36.6663 19.9729 36.6663C19.4912 36.6663 19.17 36.5013 18.8488 36.1713L4.71773 21.6498C2.79075 19.6696 1.66669 16.8644 1.66669 14.0591C1.66669 11.2538 2.79075 8.44852 4.87831 6.46832C8.89283 2.34291 15.4766 2.34291 19.4912 6.46832L20.1335 7.12839L20.7758 6.46832C22.7028 4.48812 25.4327 3.33301 28.1625 3.33301ZM20.1335 32.7059L33.1406 19.3396C34.5858 17.8545 35.3887 16.0393 35.3887 14.0591C35.3887 12.0789 34.5858 10.2637 33.3011 8.77855C31.8559 7.45842 30.0895 6.63334 28.1625 6.63334C26.075 6.63334 24.3086 7.45842 22.8634 8.77855L21.2576 10.5937C20.6152 11.2538 19.6518 11.2538 19.0094 10.5937L17.243 8.77855C15.7978 7.2934 14.0314 6.63334 12.1044 6.63334C10.3381 6.63334 8.41109 7.2934 7.12644 8.77855C5.84179 10.2637 5.03889 12.0789 5.03889 14.0591C5.03889 16.0393 5.68121 17.8545 7.12644 19.3396L20.1335 32.7059Z" fill="black"/>
        <mask id="mask0_620_5120" maskUnits="userSpaceOnUse" x="1" y="3" width="38" height="34">
            <path fillRule="evenodd" clipRule="evenodd" d="M28.1625 3.33301C30.8924 3.33301 33.4617 4.48812 35.3887 6.46832C37.4762 8.44852 38.6003 11.2538 38.2791 14.0591C38.2791 16.8644 37.1551 19.6696 35.2281 21.6498L21.097 36.1713C20.7758 36.5013 20.4547 36.6663 19.9729 36.6663C19.4912 36.6663 19.17 36.5013 18.8488 36.1713L4.71773 21.6498C2.79075 19.6696 1.66669 16.8644 1.66669 14.0591C1.66669 11.2538 2.79075 8.44852 4.87831 6.46832C8.89283 2.34291 15.4766 2.34291 19.4912 6.46832L20.1335 7.12839L20.7758 6.46832C22.7028 4.48812 25.4327 3.33301 28.1625 3.33301ZM20.1335 32.7059L33.1406 19.3396C34.5858 17.8545 35.3887 16.0393 35.3887 14.0591C35.3887 12.0789 34.5858 10.2637 33.3011 8.77855C31.8559 7.45842 30.0895 6.63334 28.1625 6.63334C26.075 6.63334 24.3086 7.45842 22.8634 8.77855L21.2576 10.5937C20.6152 11.2538 19.6518 11.2538 19.0094 10.5937L17.243 8.77855C15.7978 7.2934 14.0314 6.63334 12.1044 6.63334C10.3381 6.63334 8.41109 7.2934 7.12644 8.77855C5.84179 10.2637 5.03889 12.0789 5.03889 14.0591C5.03889 16.0393 5.68121 17.8545 7.12644 19.3396L20.1335 32.7059Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_620_5120)">
            <rect width="40" height="40" fill="white"/>
        </g>
    </svg>

}

export default Heart;
