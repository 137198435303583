import {Box, Grid, styled, Typography, useTheme} from "@mui/material";
import DarkerLine from "../ui/icons/DarkerLine";
import BubbleLeft from '../assets/images/large-dark-bubble-left.png';
import BubbleRight from '../assets/images/large-dark-bubble-right.png';

type Props = {
    content: any;
}

const SimpleStatistics = ({content}: Props) => {
    const {palette} = useTheme();

    const GridStyledItem = styled(Grid) ({
        display: 'grid',
        justifyItems: 'center',
        alignContent: 'start'
    })

    const TypographyStyled = styled(Typography) ({
        textAlign: 'center'
    })

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" xs={12}
              sx={{
                  backgroundColor: palette.secondary.light,
                  position: 'relative',
                  minHeight: '430px'
              }}>
            <Box component='img' sx={{
                position: 'absolute',
                top: {sm: '40px', lg: '10px'},
                left: '-40px',
                zIndex: 2,
                display: {xs: 'none', sm: 'block'}
            }}
                 alt='Fundacja Pomocy Dzieciom'
                 src={BubbleLeft}
            />
            <Box component='img' sx={{
                position: 'absolute',
                top: {sm: '480px', lg: '160px'},
                right: 0,
                zIndex: 2,
                display: {xs: 'none', md: 'block'},
            }}
                 alt='Fundacja Pomocy Dzieciom'
                 src={BubbleRight}
            />
            <Grid item pt={12}>
                <Typography variant={'h5'} color={palette.custom.greenDarkText} pt={'70px'} pb={4}>{content.title}</Typography>
            </Grid>
            <Grid container item justifyContent="center" alignItems="space-between" pb={8} sx={{zIndex: 3}}>
                <GridStyledItem item xs={10} lg={2}>
                    <TypographyStyled variant={'h1'} sx={{fontWeight: 'bold'}} color={palette.custom.whiteText} pt={1}>{content.box[0].number}</TypographyStyled>
                    <TypographyStyled variant={'h6'} sx={{fontWeight: 'bold'}} color={palette.custom.greenDarkText}>{content.box[0].subtitle}</TypographyStyled>
                    <TypographyStyled color={palette.custom.greenDarkText} pb={{xs: 2, lg: 0}} pt={{xs: 1, lg: 3}} width={{xs: '100%', xl: '80%'}}>{content.box[0].description}</TypographyStyled>
                </GridStyledItem>
                <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                    <DarkerLine />
                </Box>
                <GridStyledItem item xs={10} lg={2}>
                    <TypographyStyled variant={'h1'} sx={{fontWeight: 'bold'}} color={palette.custom.whiteText} pt={1}>{content.box[1].number}</TypographyStyled>
                    <TypographyStyled variant={'h6'} sx={{fontWeight: 'bold'}} color={palette.custom.greenDarkText}>{content.box[1].subtitle}</TypographyStyled>
                    <TypographyStyled sx={{alignSelf: 'end'}} color={palette.custom.greenDarkText} pb={{xs: 2, lg: 0}} pt={{xs: 1, lg: 3}} width={{xs: '100%', xl: '80%'}}>{content.box[1].description}</TypographyStyled>
                </GridStyledItem>
                <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                    <DarkerLine />
                </Box>
                <GridStyledItem item xs={10} lg={2}>
                    <TypographyStyled variant={'h1'} sx={{fontWeight: 'bold'}} color={palette.custom.whiteText} pt={1}>{content.box[2].number}</TypographyStyled>
                    <TypographyStyled variant={'h6'} sx={{fontWeight: 'bold'}} color={palette.custom.greenDarkText}>{content.box[2].subtitle}</TypographyStyled>
                    <TypographyStyled sx={{alignSelf: 'end'}} color={palette.custom.greenDarkText} pb={{xs: 2, lg: 0}} pt={{xs: 1, lg: 3}} width={{xs: '100%', xl: '80%'}}>{content.box[2].description}</TypographyStyled>
                </GridStyledItem>
                <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                    <DarkerLine />
                </Box>
                <GridStyledItem item xs={10} lg={2}>
                    <TypographyStyled variant={'h1'} sx={{fontWeight: 'bold'}} color={palette.custom.whiteText} pt={1}>{content.box[3].number}</TypographyStyled>
                    <TypographyStyled variant={'h6'}  sx={{fontWeight: 'bold'}} color={palette.custom.greenDarkText}>{content.box[3].subtitle}</TypographyStyled>
                    <TypographyStyled sx={{alignSelf: 'end'}} color={palette.custom.greenDarkText} pb={{xs: 2, lg: 0}} pt={{xs: 1, lg: 3}} width={{xs: '100%', xl: '80%'}}>{content.box[3].description}</TypographyStyled>
                </GridStyledItem>
            </Grid>
        </Grid>
    )
}

export default SimpleStatistics;
