import {Box, Grid, IconButton, Typography, useTheme} from "@mui/material";
import DownloadIcon from "./icons/DownloadIcon";
import {useState} from "react";

type Props = {
    title: string;
    url: string;
    subtype: string;
    dark?: boolean;
    caption?: string;
}

const DownloadTile = ({title, url, subtype, dark, caption}: Props) => {
    const {palette} = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return <Grid item container alignItems={"center"} sx={{position: 'relative', backgroundColor: dark ? palette.grey[200] : palette.grey[100], height: {xs: 'auto', md: "92px"}}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Grid item container justifyContent={"center"} alignItems={"center"} sx={{backgroundColor: palette.primary.main, borderRadius: "50%", ml: "24px", width: "44px", height: "44px"}}>
            <Typography sx={{fontFamily: "Exo", wordBreak: 'break-all', fontSize: "17px", color: "white", textAlign: "center"}}>{subtype && subtype.toUpperCase()}</Typography>
        </Grid>
        <Typography sx={{fontWeight: 700, ml: "24px", wordBreak: 'break-all'}} color={palette.primary.main}>{title}</Typography>
        <IconButton sx={{m: "auto", borderRadius: '0', display: 'flex', flexDirection: 'column', gap: '4px', mr: {xs: "24px", md: "118px"}}} href={url} target={"_blank"}>
            <DownloadIcon/>
            <Typography sx={{color: palette.grey[900]}}>pobierz</Typography>
        </IconButton>
        {caption && <Box sx={{
            position: 'absolute',
            top: '80%',
            left: '50%',
            transform: 'translateX(-50%)',
            background: '#e0e0e0',
            padding: '6px 12px',
            borderRadius: '12px',
            maxWidth: '300px',
            fontSize: '14px',
            zIndex: 2,
            lineHeight: 'normal',
            display: isHovered ? 'flex' : 'none',
            wordBreak: 'break-all'
        }}>
            {caption}
        </Box>}
    </Grid>
}

export default DownloadTile;
