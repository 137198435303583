import Page from "../model/Page";
import {getAuthRequest, getRequest, getRequestPosts} from "./api";
import Menu from "../model/Menu";
import Settings from "../model/Settings";
import Post from "../model/Post";

export const getAllPages = async () => {
    const data = await getRequest('/wp/v2/pages?per_page=100', (error) => {});
    return Page.fromApiResponse(data);
}

export const getAllPosts = async () => {
    const data = await getRequest('/wp/v2/news?_embed=1&per_page=10', (error) => {});
    return data.map(data => Post.fromApiResponse(data));
}

let resultMerged = [] as any[];

export const getAllPostsByPage = async (setPosts: any, page: number) => {
    const data = await getRequestPosts(`/wp/v2/news?_embed=1&per_page=10&page=${page}`, page, setPosts, (error) => {});
    if(page === 1) {
        const result = data.data.map((data: any) => Post.fromApiResponse(data));
        resultMerged = result
    } else {
        const result = data.data.map((data: any) => Post.fromApiResponse(data));
        resultMerged = [...resultMerged, ...result]
    }
    if(page++ < +data.headers['x-wp-totalpages']) {
        await getAllPostsByPage(setPosts, page++)
    }
    return resultMerged
}

export const getMenu = async () => {
    const data = await getRequest('/ex/menu?location=topbar', (error) => {});
    return Menu.fromApiResponse(data);
}

export const getSettings = async () => {
    const data = await getRequest('/acf/v3/options/option', (error) => {});
    return Settings.fromApiResponse(data);
}

export const getAccount = async (id: number, token: string, redirectToLogin: () => void) => {
    return await getAuthRequest(`/ex/account?id=${id}`, token, redirectToLogin);
}

export const getAllAccounts = async (token: string, redirectToLogin: () => void) => {
    return await getAuthRequest(`/ex/accounts`, token, redirectToLogin);
}
