import {useContext, useEffect} from "react";
import ContentContext from "../../store/content-context";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {Box, Grid, Typography} from "@mui/material";
import transferForm from "../../assets/images/transfer-form.png";


const TransferForm = () => {
    const {foundation} = useContext(ContentContext);

    useEffect(() => {
        const input = document.getElementById('divToPrint');
        if(input && foundation.name) {
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    // @ts-ignore
                    pdf.addImage(imgData, 'JPEG', 0, 0);
                    pdf.save("Przelew dla fundacji.pdf");
                })
            ;
        }
    },[foundation.name])

    return (
        <Box sx={{backgroundColor: "gray", width: "100%", height: "400mm"}}>
            <Box id={"divToPrint"} sx={{
                maxWidth: '210mm',
                maxHeight: '297mm',
                minWidth: '210mm',
                minHeight: '297mm',
                backgroundColor: "white",
                position: "absolute",
                zIndex: 100,
            }}>
                <Grid item container
                      flexDirection={"column"}
                      sx={{
                          height: '103mm',
                          width: '152mm',
                          background: `url(${transferForm})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          margin: '20mm',
                          p: '3mm 9mm',
                          border: "1px dashed black"
                      }}>
                    <Typography color={"black"} variant={"body2"}>{foundation.name}</Typography>
                    <Typography color={"black"} variant={"body2"} mt={"3mm"}>{foundation.streetAndNumber} {foundation.postalCode} {foundation.city}</Typography>
                    <Typography color={"black"} variant={"body2"} mt={"2mm"} sx={{letterSpacing: "6px"}}>{foundation.account}</Typography>
                    <Typography color={"black"} variant={"body2"} mt={"36mm"}>Darowizna dla fundacji: </Typography>
                    <Typography color={"black"} variant={"body2"} mt={"2mm"}>{foundation.name}</Typography>
                </Grid>
            </Box>
        </Box>
    )
}

export default TransferForm;