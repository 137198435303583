import SectionTitle from "../ui/SectionTitle";
import {Grid, Typography, useTheme} from "@mui/material";
import DownloadTile from "../ui/DownloadTile";
import {StyledButton} from "./DownloadFilesGroup";
import SnackbarContext from "../store/snackbar-context";
import {useContext} from "react";

type Props = {
    content: any
}

const DownloadFiles = ({content}: Props) => {
    const {palette} = useTheme()
    const downloadAll = (files: any[]) => {
        files && files.forEach(({file}: any) => {
            const filename = file.url.substring(file.url.lastIndexOf('/')+1);
            const link = document.createElement('a')
            link.href = file.url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        })
    }

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", position: "relative"}}>
        {content.title ? <SectionTitle title={content.title} color={palette.custom.greenDarkTextBlack} /> : ''}
        <Grid container item pt={content.title ? 0 : 12} pb={6} justifyContent='flex-start' sx={{width: {lg: "1175px", xs: "90%"}}}>
            {content.subtitle ? <Typography>{content.subtitle}</Typography> : ''}
        </Grid>
        <Grid item container flexDirection={"column"} sx={{width: {lg: "1175px", xs: "90%"}, mb: "96px"}}>
            {content.files.map(({file}: any, index: number) => <DownloadTile key={index} title={file.title} subtype={file.filename.split('.').pop()} url={file.url} caption={file.caption} dark={index % 2 != 0}/>)}
        </Grid>
        {content.button_download_all ?
        <Grid container item justifyContent={{xs: 'center', md: 'flex-end'}} sx={{width: {lg: "1175px", xs: "90%"}}} mb={12} mt={-4}>
            <StyledButton color={"secondary"} onClick={() => downloadAll(content.files)}>Pobierz wszystko</StyledButton>
        </Grid>  : ''}
    </Grid>
}

export default DownloadFiles;
