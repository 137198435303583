class Foundation {
    account: string;
    bank: string;
    city: string;
    euroPlAccount: string;
    krs: string;
    nip: string;
    name: string;
    postalCode: string;
    streetAndNumber: string;
    swiftCode: string;
    phones: string[];
    facebookLink: string;
    instagramLink: string;
    youtubeLink: string;
    tiktokLink: string;

    private constructor(account: string, bank: string, city: string, euroPlAccount: string, krs: string, nip: string, name: string, postalCode: string, streetAndNumber: string, swiftCode: string, phones: string[], facebookLink: string, instagramLink: string, youtubeLink: string, tiktokLink: string) {
        this.account = account;
        this.bank = bank;
        this.city = city;
        this.euroPlAccount = euroPlAccount;
        this.krs = krs;
        this.nip = nip;
        this.name = name;
        this.postalCode = postalCode;
        this.streetAndNumber = streetAndNumber;
        this.swiftCode = swiftCode;
        this.phones = phones;
        this.facebookLink = facebookLink;
        this.instagramLink = instagramLink;
        this.youtubeLink = youtubeLink;
        this.tiktokLink = tiktokLink;
    }

    public static fromApiResponse({acf}: any) {
        return new Foundation(
            acf.account,
            acf.bank,
            acf.city,
            acf.euro_pl_account,
            acf.krs,
            acf.nip,
            acf.name,
            acf.postal_code,
            acf.street_and_number,
            acf.swift_bic_code,
            acf.phones && acf.phones.map((phone: any) => phone.number),
            acf.facebook,
            acf.instagram,
            acf.youtube,
            acf.tiktok
        )
    }

}

export default Foundation;
