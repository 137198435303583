import {Box, Grid, useTheme} from "@mui/material";
import {useState} from "react";
import ArrowRight from "../ui/icons/ArrowRight";
import CloseIconBlack from "../ui/icons/CloseIconBlack";
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
    content: any;
}

const Gallery = ({content}: Props) => {
    const {palette} = useTheme();
    const theme = useTheme();
    const [imageIndex, setImageIndex] = useState(0);
    const [sliderHandle, setSliderHandle] = useState(false);
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));

    const handleSlider = (index: number) => {
        setSliderHandle(true)
        setImageIndex(index)
    }

    const prevSlide = () => {
        setImageIndex(imageIndex === 0 ? content.length - 1 : imageIndex - 1)
    }

    const nextSlide = () => {
        setImageIndex(imageIndex === content.length - 1 ? 0 : imageIndex + 1)
    }

    const hideSlider = () => {
        setSliderHandle(false)
        setImageIndex(0)
    }

    return (
        <Grid container flexWrap={'wrap'} justifyContent={{xs: 'center', sm: 'flex-start'}} sx={{gap: '2vw', width: {lg: "1175px", xs: "90%"}, m: "48px auto 96px auto", color: "primary", zIndex: 1}}>
            {
                content.map((item: any, index: number) => {
                    return (
                        <Box onClick={() => handleSlider(index)} sx={{flex: '0 1 12.8%', '&:hover': {cursor: 'pointer'}}}>
                            <Box component={'img'} src={item.sizes.thumbnail} sx={{borderRadius: '6px'}} />
                        </Box>
                    )
                })
            }
            <Box onClick={hideSlider} sx={{display: `${sliderHandle ? 'block' : 'none'}`, position: 'fixed', inset: 0, zIndex: 3, height: '100dvh', width: '100dvw', backgroundColor: palette.primary.main, opacity: .6}} />
            <Box onClick={hideSlider} sx={{display: `${sliderHandle ? 'flex' : 'none'}`, position: 'fixed', top: '10%', right: '5%', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', opacity: .5, backgroundColor: palette.primary.light, zIndex: 5, '&:hover': {cursor: 'pointer', opacity: 1}}}>
                <CloseIconBlack />
            </Box>
            <Grid item sx={{display: `${sliderHandle ? 'flex' : 'none'}`, flexWrap: {xs: 'wrap', sm: 'nowrap'}, position: 'fixed', top: '50%', left: '50%', zIndex: 4, transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', gap: {xs: '40px', sm: '5px', md: '40px'}}}>
                {content.length > 1 && matchesSm ? <Box onClick={prevSlide} sx={{display: `${sliderHandle ? 'flex' : 'none'}`, justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', opacity: .7, backgroundColor: palette.custom.greenDarkText, transform: 'rotate(180deg)', zIndex: 5, '&:hover': {cursor: 'pointer', opacity: 1}}}>
                    <ArrowRight />
                </Box> : ''}
                <Box component={'img'} src={content[imageIndex].sizes.medium_large} sx={{width: {xs: '300px', sm: '480px', lg: 'auto'}, display: `${sliderHandle ? 'block' : 'none'}`, '&::selection': {backgroundColor: 'transparent'}}} />
                {content.length > 1 && !matchesSm ? <Box onClick={prevSlide} sx={{display: `${sliderHandle ? 'flex' : 'none'}`, justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', opacity: .7, backgroundColor: palette.custom.greenDarkText, transform: 'rotate(180deg)', zIndex: 5, '&:hover': {cursor: 'pointer', opacity: 1}}}>
                    <ArrowRight />
                </Box> : ''}
                {content.length > 1 ? <Box onClick={nextSlide} sx={{display: `${sliderHandle ? 'flex' : 'none'}`, justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', opacity: .7, backgroundColor: palette.custom.greenDarkText, zIndex: 5, '&:hover': {cursor: 'pointer', opacity: 1}}}>
                    <ArrowRight />
                </Box> : ''}
            </Grid>
        </Grid>
    )
}

export default Gallery;
