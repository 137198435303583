import {Grid, Typography} from "@mui/material";
import {useContext} from "react";
import ContentContext from "../../store/content-context";
import PaymentBanner from "./PaymentBanner";
import {GreenButton} from "../../ui/GreenButton";

type Props = {
    content: any;
}

const PaymentDetails = ({content}: Props) => {
    const {foundation} = useContext(ContentContext);

    return <Grid container justifyContent={"center"} alignItems={"center"} sx={{width: {lg: "1175px", xs: "90%"}, m: "0 auto", color: "transparent", position: "relative"}}>
        <Grid item container flexDirection={{xs: "column", lg: "row"}} sx={{width: "80%"}} flexWrap={"nowrap"} alignItems={"center"}>
            <Grid item container flexDirection={"column"} justifyContent={"center"} alignItems={"Center"}>
                <Typography variant={"h6"} textAlign={{xs: "center", lg: "left"}} color={"primary"}>Rozliczając zeznanie podatkowe, przekaż 1.5% wpisując nasz KRS oraz NIP:</Typography>
                <Typography variant={"h6"} textAlign={{xs: "center", lg: "left"}} color={"primary"} sx={{fontWeight: 400}} mt={"8px"}>KRS: {foundation.krs}</Typography>
                <Typography variant={"h6"} textAlign={{xs: "center", lg: "left"}} color={"primary"} sx={{fontWeight: 400}}>NIP: {foundation.nip}</Typography>
            </Grid>
            <Grid item ml={{xs: 0, lg:"67px"}} mt={{xs: "96px", lg: 0}}>
                <GreenButton onClick={() => window.open(content.e_pity_link, "_blank")}>Pobierz program E-pity</GreenButton>
            </Grid>
        </Grid>
        <PaymentBanner content={content.banner_text} image={content.image} link={content.banner_link}/>
    </Grid>
}

export default PaymentDetails;
