import {createContext, ReactNode, useContext, useReducer} from "react";
import SnackbarContext from "./snackbar-context";
import {sendLoginRequest} from "../api/auth-api";
import jwt_decode from "jwt-decode";

type AuthContextType = {
    token: string | null,
    email: string | null,
    id: number | null,
    firstName: string | null,
    lastName: string | null,
    isAdmin: boolean,
    login: (login: string, password: string) => void,
    logout: () => void,
};

type ActionType = {
    type: string;
    value?: any;
};

const LOGIN = "setLogin";
const LOGOUT = "setLogout";
const USER = "userData";

const INITIAL_AUTH_STATE: AuthContextType = {
    token: null,
    email: null,
    id: null,
    firstName: null,
    lastName: null,
    isAdmin: false,
    login: (login: string, password: string) => {},
    logout: () => {},
};

const authReducer = (state: AuthContextType, action: ActionType): AuthContextType => {
    switch (action.type) {
        case LOGIN: {
            console.log(action.value)
            return {
                ...state,
                token: action.value.token,
                email: action.value.email,
                id: action.value.id,
                firstName: action.value.firstName,
                lastName: action.value.lastName,
                isAdmin: action.value.isAdmin,
            };
        }
        case LOGOUT: {
            return {
                ...state,
                token: null,
                email: null,
                id: null,
                firstName: null,
                lastName: null,
                isAdmin: false,
            };
        }
        default:
            return state;
    }
};

const getInitialState = () => {
    const userJson = localStorage.getItem(USER);
    if(userJson) {
        const user = JSON.parse(userJson);
        try {
            const tokenData = jwt_decode(user.token) as any;
            return {
                ...INITIAL_AUTH_STATE,
                token: user.token,
                email: user.email,
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                isAdmin: tokenData.roles.includes('administrator'),
            }
        } catch (error) {
            console.log(error);
        }
    }
    return INITIAL_AUTH_STATE;
}

export const AuthContextProvider = ({children} : {children: ReactNode} ) => {
    const [authState, dispatch] = useReducer(authReducer, getInitialState());
    const {setMsg} = useContext(SnackbarContext);

    const login = (username: string, password: string) => {
        sendLoginRequest(username, password, (error) => setMsg({msg: error}))
            .then((data) => {
                if(data) {
                    const tokenData = jwt_decode(data.token) as any;
                    const user = {token: data.token, firstName: data.firstName, lastName: data.lastName, email: data.email, id: data.id, isAdmin: tokenData.roles.includes('administrator')}
                    localStorage.setItem(USER, JSON.stringify(user));
                    dispatch({type: LOGIN, value: {...data, isAdmin: user.isAdmin}});
                }
            })
            .catch((error) => {
                setMsg({msg: "Wystąpił błąd podczas logowania. Spróbuj ponownie później! "})
                console.log(error);
            })
    };

    const logout = () => {
        localStorage.removeItem(USER);
        dispatch({type: LOGOUT});
    };

    const contextValue: AuthContextType = {
        ...authState,
        login: login,
        logout: logout,
    };
    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const AuthContext = createContext<AuthContextType>({
    ...INITIAL_AUTH_STATE,
});

export default AuthContext;
