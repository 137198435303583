const GreenMailIcon = () => {
    return <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M59.476 30C59.476 46.5685 46.1618 60 29.738 60C13.3142 60 0 46.5685 0 30C0 13.4315 13.3142 0 29.738 0C46.1618 0 59.476 13.4315 59.476 30Z" fill="#3D8D4F"/>
        <g clipPath="url(#clip0_0_1)">
            <path d="M41.875 18H17.125C14.8563 18 13 19.8265 13 22.0588V36.9412C13 39.1735 14.8563 41 17.125 41H41.875C44.1437 41 46 39.1735 46 36.9412V22.0588C46 19.8265 44.1437 18 41.875 18ZM41.215 20.7059L29.555 29.9871L17.5237 20.7059H41.215ZM18.665 38.2941L26.3925 31.0288H22.4187L15.8187 37.2524C15.7912 37.1441 15.75 37.0494 15.75 36.9412V30.1224V27.4706V24.7647V23.4388V22.7759L29.5962 33.4506L32.635 31.0288L40.4862 38.2941H18.665ZM43.25 23.4388V24.7647V27.4706V30.1224V36.9412C43.25 36.9953 43.2225 37.0494 43.2225 37.1035L36.6363 31.0288H32.635L43.25 22.5729V23.4388Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="45.3504" height="45.75" fill="white" transform="translate(6.69104 7.50098)"/>
            </clipPath>
        </defs>
    </svg>
}

export default GreenMailIcon;
